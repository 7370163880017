import React, { useContext, useEffect, useState } from "react";
import Header from "../Components/Header";
import SmallHero from "../Components/SmallHero";
import StepNavigation from "./StepNavigation";
import Footer from "../Components/Footer";
import { ProfileContext } from "../Context/ProfileContext";
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import { doRequest } from "../utils/doRequest";
import { decryptedUserId } from "../utils";

export default function Summary() {
  const { profile } = useContext(ProfileContext);
  const [expertise,setExpertise] = useState([]);


  useEffect(() => {
    let isSubscribed = true;
    doRequest(
      "React",
      "/lib/storage/users/"+ decryptedUserId() +"/questions.json",
      "GET",
      {},
      true,
      (err, data) => {
        if (isSubscribed) {
          console.log(data)
          setExpertise(data.expertise);
          // setQuestions(data.questions);
        }
      }
    );

    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <div class="base-box scroll-box" id="page-top">
      <Header hideOptions={true} />

      <div class="base-item">
        <SmallHero />

        <main class="main-content">
          <StepNavigation />

          <section class="modul backend-editor step-5">
            <div class="item-box" id="personal-data-content">
              <div class="form-box">
                <h3>Profile - Preview</h3>

                <p class="infotext checkmark-box">
                  Your Profile is available for matchmaking{" "}
                  <span class="icon-box checkmark">
                    <i class="icon"></i>
                  </span>
                </p>
                {console.log(profile)}
                {profile.activatematch ? (
                  <>
                   <div class="preview-box">
                  <div class="user-profile-data">
                    <div class="user-image-data">
                      <figure class="user-image-preview">
                        {profile.image? (<img src={`${process.env.REACT_APP_SERVER_ENDPOINT}${profile.image}`} />) : ''}
                      </figure>
                    </div>
                    <div class="user-text-data">
                      <p>
                        <span class="preview-text-label">
                          {profile.salutation ? parseInt(profile.salutation) == 1 ?  'Mr.' : '' : '' }{' '}
                          {profile.salutation ? parseInt(profile.salutation) == 3 ? 'Ms.' : '' : '' }{' '}
                          {profile.title}{' '}
                          {profile.first_name} {' '}
                          {profile.last_name}{', '}
                        </span>
                        <span class="preview-text-label"><strong>{profile.position}{' '}</strong></span>
                        <span class="preview-text-label">{profile.company}</span>
                        <span class="preview-text-label">
                          <a href={`mailto:${profile.email}`}>
                            {profile.email}
                          </a>
                        </span>
                        <span class="preview-text-label">
                          <a href={`tel:{profile.telephone`}>{profile.telephone}</a>
                        </span>
                      </p>
                    </div>
                  </div>

                  <h5>My Interests</h5>

                  <div class="hashtag-box">
                    <ul class="hashtag">
                        {expertise.length ? 
                          expertise.map((expertAt) => (
                            expertAt.checked ? (<li htmlFor="company-topic-2" className="hashtag-label active"> {expertAt.name} </li>) : ''
                          )) : ''
                        }
                    </ul>
                  </div>

                  <h5>About Me</h5>
                  {profile.description && parse(profile.description)}
                </div>
                <hr />
                <h3>Company Space - Preview</h3>

                {profile.activatevideo ? (
                  <p class="info-text checkmark-box">Video Chat Appointment activated <span class="icon-box checkmark"><i class="icon"></i></span></p>
                ) : ''}

                <div class="preview-box">
                  <div class="company-profile-data">
                      <div class="company-user-image-data">
                        <figure class="user-image-preview">
                            {profile.image? (<img src={`${process.env.REACT_APP_SERVER_ENDPOINT}${profile.image}`} />) : ''}
                        </figure>
                      </div>
                      <div class="company-text-data">
                        <div class="company-text-data-wrapper">
                          <p>
                            <span class="preview-text-label">
                                {profile.salutation ? parseInt(profile.salutation) == 1 ?  'Mr.' : '' : '' }{' '}
                                {profile.salutation ? parseInt(profile.salutation) == 3 ? 'Ms.' : '' : '' }{' '}
                                {profile.title}{' '}
                                {profile.first_name} {' '}
                                {profile.last_name}{' , '}    
                            </span>
                            <span class="preview-text-label"><strong>{profile.position}{' '}</strong></span>
                          </p>
                        </div>
                      </div>
                  </div>
                  <div class="text-box">
                    <h5 class="regular">Short Description</h5>
                    {profile.shortdesc && parse(profile.shortdesc)}
                  </div>
						    </div>
                  </>
                ) : (
                    <p>If you want to connect with interesting exhibitors, speakers and visitors based on their profile press „Edit Profile“ and check the box „Activate Matchmaking“</p>
                )}
              </div>

              <div class="button-box">
                  <div class="field-item">
                    <Link to="/user-editor/matchmaking" class="button previous show-loader">
                      {/* <a class="button previous show-loader" href="/user-editor"> */}
                        <span class="button-label">Edit Profile</span>
                        <span class="icon-box">
                          <i class="icon"></i>
                        </span>
                      {/* </a> */}
                    </Link>
                  </div>
                  <div class="field-item">
                    <Link to="/live" class="button next show-loader">
                      <span class="button-label">Done</span>
                      <span class="icon-box">
                        <i class="icon"></i>
                      </span>
                    </Link>
                  </div>
                </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
}
