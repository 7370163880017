import React, { useContext, useEffect, useState } from "react";
import SmallHero from "../Components/SmallHero";
import StepNavigation from "./StepNavigation";

import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../Context/ProfileContext";
import { doRequest } from "../utils/doRequest";
import { cryptedUserId, decryptedUserId } from "../utils";
import { toast } from "react-toastify";
import Footer from "../Components/Footer";
import Header from "../Components/Header";


export default function Professional() {
  document.body.classList.add("backend");
  document.body.classList.add("bd-user-profile");

  const { profile } = useContext(ProfileContext);
  const [company, setCompany] = useState("");
  const [showMenu, setShowMenu] = useState("");
  const [questions, setQuestions] = useState([]);
  const [expertise,setExpertise] = useState([]);
  const navigate = useNavigate();

  function toggle(value) {
    if (showMenu != value) {
      setShowMenu(value);
    } else {
      setShowMenu("");
    }
  }

  const totalExpertise = () => expertise.filter(props => props.checked).length;


  function expertiseChanged(e) {
    // console.log(totalExpertise() < process.env.REACT_APP_MAX_CHOICE_ALLOWED);
    if(totalExpertise() < process.env.REACT_APP_MAX_CHOICE_ALLOWED) {

      const newState = expertise.map(obj => {
        if(obj.uid == e.target.value) {
          return { ...obj, checked : e.target.checked };
        }
        return obj;
      })

      // toast.success('Added', { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined });
      setExpertise(newState);
    } else {
      toast.error('❌ Error occured', { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined });
    }
    
  }

  function handleQuestion(e) {
    const newState = questions.map((question,queIndex) => {
      let answers = question.answers.map((answer) => { 
        if(answer.uid == e.target.value) {

          if(questions[queIndex].questiontype == 0) {
            questions[queIndex].answers = questions[queIndex].answers.map((answer) => {
              return { ...answer , checked: false };
            });
          }
          
          let questionCount = 0;

          if(questions[queIndex].questiontype == 1 && question.uid == process.env.REACT_APP_AREA_OF_EXPERTISE_QUESTION_ID) {
            questionCount = questions[queIndex].answers.filter(props => props.checked).length;
          } 
          
          if(questionCount < process.env.REACT_APP_MAX_CHOICE_ALLOWED || !e.target.checked) {
            return { ...answer, checked : e.target.checked };
          } else {
            toast.error('❌ ' + process.env.REACT_APP_MAX_CHOICE_ALLOWED +  ' options already selected', { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined });
            return { ...answer };
          }
        } else {
          return { ...answer };
        }
      })

      return { ...question,  answers : answers }

    });

    setQuestions(newState);

  }


  useEffect(() => {
    let isSubscribed = true;
    doRequest(
      "React",
      "/lib/storage/users/"+ decryptedUserId() +"/questions.json",
      "GET",
      {},
      true,
      (err, data) => {
        if (isSubscribed) {
          
          setExpertise(data.expertise);
          setQuestions(data.questions);
        }
      }
    );

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      setCompany(profile.company);
    }

    return () => {
      isSubscribed = false;
    };
  }, [profile]);

  function InputType({ questiontype, answer, question }) {
    if (questiontype == 1) {
      return (
        <>
          <input
            type="checkbox"
            id={`answer-${question.uid}${answer.uid}`}
            value={answer.uid}
            question={question.uid}
            defaultChecked={answer.checked}
            checked={answer.checked}
            onChange={handleQuestion}
          />
          <label htmlFor={`answer-${question.uid}${answer.uid}`}>
            {answer.answer}
            {answer.description ? (
              <span className="description">{answer.description}</span>
            ) : (
              ""
            )}
          </label>
        </>
      );
    } else {
      return (
        <>
          <input
            type="radio"
            name={question.uid}
            question={question.uid}
            value={answer.uid}
            id={`answer-${question.uid}${answer.uid}`}
            defaultChecked={answer.checked}
            onChange={handleQuestion}
          />
          <label htmlFor={`answer-${question.uid}${answer.uid}`}>
            {answer.answer}
            {answer.description ? (
              <span className="description">{answer.description}</span>
            ) : (
              ""
            )}
          </label>
        </>
      );
    }
  }

  function handleNext(e) {
    e.preventDefault();
    const companyTopics = expertise.filter((expert) => expert.checked == true ).map(({uid}) => uid);
    
    const questionAns = questions.map((question) => {
      return { [question.uid]: question.answers.filter((answer) => answer.checked == true).map(({uid}) => uid) };
    });
    
    doRequest('Server', '/api/v1/Frontend/user/updateUser','POST', 
    {
      "userid": cryptedUserId(),
      "step": 2,
      "questions": questionAns,
      "company-topic": companyTopics,
    }, true, (err, data) => {
      if(data == 'Success response') {
        toast.success('User Updated Successfully', {hideProgressBar: false  });
        return navigate('/user-editor/exhibitor');
      }
    })
    
  }
  function handlePrevious(e) {

    e.preventDefault();
    const companyTopics = expertise.filter((expert) => expert.checked == true ).map(({uid}) => uid);
    
    const questionAns = questions.map((question) => {
      return { [question.uid]: question.answers.filter((answer) => answer.checked == true).map(({uid}) => uid) };
    });
    
    doRequest('Server', '/api/v1/Frontend/user/updateUser','POST', 
    {
      "userid": cryptedUserId(),
      "step": 2,
      "questions": questionAns,
      "company-topic": companyTopics,
    }, true, (err, data) => {
      if(data == 'Success response') {
        toast.success('User Updated Successfully', {hideProgressBar: false  });
        return navigate('/user-editor/personal');
      }
    })
  }

  return (
    <div className="base-box scroll-box" id="page-top">
      <Header hideOptions={true} />

      <div className="base-item">
        <SmallHero />

        <main className="main-content">
          <StepNavigation activeLink="professional" />

          <section className="modul backend-editor step-2">
            <div className="item-box">
              <form id="user-profile-step2" action="" method="post">
                {/* <div className="validation-box errorDiv step2" >
                    <p>Errors</p>
                    <ul>
                    </ul>
                  </div> */}
                <div className="form-box">
                  <h3>Your Profile Professional Data</h3>
                  <p className="info-text">
                    Your data is not visible to other users unless you specify
                    otherwise.
                  </p>

                  <div className="form-row company-box">
                    <div className="field-item">
                      <input
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                      <label className="field-label" htmlFor="user-company">
                        Company
                      </label>
                    </div>
                  </div>

                  <div className="form-row question-box">
                    {questions.length ? (
                      questions.map((question,index) => (
                        <div className="field-item question-item" key={index}>
                          <div className="field-item-wrapper">
                            <label
                              className={
                                showMenu == question.uid
                                  ? "block-label selector active"
                                  : "block-label selector"
                              }
                              onClick={() => toggle(question.uid)}
                            >
                              <span>
                                {question.usertext}
                                {question.uid ==
                                process.env
                                  .REACT_APP_AREA_OF_EXPERTISE_QUESTION_ID ? (
                                  <>
                                    {question.questiontype == 1 && (
                                      <span className="description">
                                        Multiple choices allowed. You can select
                                        a maximum of {process.env.REACT_APP_MAX_CHOICE_ALLOWED} topics
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {question.questiontype == 1 && (
                                      <span className="description">
                                        Multiple choices allowed
                                      </span>
                                    )}
                                  </>
                                )}
                              </span>
                              <span className="icon-box">
                                <i className="icon"></i>
                              </span>
                            </label>
                            <div className="form-row hashtag-box questions-step-2">
                              {question.answers.map((answer, index) => (
                                <div className="field-item standard-radio free-text-element" key={index}>
                                  <InputType
                                    questiontype={question.questiontype}
                                    answer={answer}
                                    question={question}
                                  />
                                  {answer.enabletext ? (
                                    <div className="field-item free-text">
                                      <input
                                        type="text"
                                        id={`free-text-${answer.uid}`}
                                        placeholder=" "
                                        className="standard-field"
                                        name="tx_user_edituser[free-text-{answer.uid}]"
                                        value={answer.freeText}
                                      />
                                      <label
                                        className="field-label"
                                        htmlFor={`free-text-${answer.uid}`}
                                      >
                                        Free Text
                                      </label>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))): ''}

                  </div>

                  <h5>
                    Select your topics of interest at CCW
                    <strong className="description">
                      Multiple choices allowed. Select maximum {process.env.REACT_APP_MAX_CHOICE_ALLOWED} topics
                    </strong>
                  </h5>

                  <div className="form-row hashtag-box topic-box">
                    {expertise.length ? (
                      expertise.map((topic) => (
                        <div className="field-item standard-radio">
                          <input
                            type="checkbox"
                            id={`company-topic-${topic.uid}`}
                            value={topic.uid}
                            defaultChecked={topic.checked}
                            checked={topic.checked}
                            onChange={expertiseChanged}
                          />
                          <label
                            htmlFor={`company-topic-${topic.uid}`}
                            className="hashtag-label"
                          >
                            {topic.name}
                            <span className="description">{topic.description}</span>
                          </label>
                        </div>
                      ))) : ''}
                  </div>

                  <div className="button-box">
                    <div className="field-item">
                      <button
                        className="button previous show-loader"
                        onClick={handlePrevious}
                      >
                        <span className="button-label">Previous</span>
                        <span className="icon-box">
                          <i className="icon"></i>
                        </span>
                      </button>
                    </div>
                    <div className="field-item">
                      <button
                        className="button next show-loader"
                        name="tx_user_edituser[saveStep2]"
                        value="next"
                        onClick={handleNext}
                      >
                        <span className="button-label">Next</span>
                        <span className="icon-box">
                          <i className="icon"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
}
