import { useEffect } from "react";
import { Link } from "react-router-dom"
import Footer from "../Components/Footer";
import Header from "../Components/Header";

import { isLogin } from '../utils';

export default function PublicPage() { 

    useEffect(() => {
      document.body.classList.add('countdown');
      return () => {
        document.body.classList.remove('countdown');
      }
    }, [])
    

    return (
        <div className="base-box" id="page-top">
        
        <Header />
          
        <div className="base-item">
          <section className="hero-content">
            <div className="item-box">
              <div className="bg-image">
                <figure className="image-box">
                  <img
                    srcSet="/images/bg-live-790.jpg 790w,
                                                           /images/bg-live-1080.jpg 1080w,
                                                           /images/bg-live.jpg 1680w"
                    sizes="(max-width: 1680px) 100vw, 1680px"
                    src="/Public/images/bg-live.jpg"
                  />
                </figure>
              </div>
  
              <div className="hero-item-box count-to-zero">
                <div className="hero-item-wrapper">
                  <div className="text-box">
                    <h1>Live and Digital - Worldwide</h1>
                    <ul className="hashtag">
                      <li>
                        Valuable &amp; Customized Content I Learn Something New
                        Today
                      </li>
                      <li>
                        Individual Matchmaking I Meet the Right Person and Company
                        Today
                      </li>
                      <li>
                        Company Spaces I Find Your Perfect Solution and Partner
                        Today
                      </li>
                      <li>
                        Appointments, Text &amp; Videochats I Get Connected Today
                      </li>
                    </ul>
                  </div>
                  <div className="teaser-box">
                    <div className="teaser-item">
                      <div className="content-box">
                        <h3>Get Your Ticket</h3>
                        <p>
                          You should be with us!
                          <br />
                          Visit physically and/or digitally!
                        </p>
                      </div>
                      <div className="button-box">
                        <Link className="button" title="Registration" to="/registration">
                          Registration
                        </Link>
                      </div>
                    </div>
  
                    <div className="teaser-item">
                      <div className="content-box">
                        <h3>For Ticketholders</h3>
                        <p>
                          <strong>Conference / Trade Show / Exhibitor</strong>
                          <br />
                          <br />
                          To get the most out of digital 
                          <br />
                          make sure to complete your user profile!
                        </p>
                      </div>
                      <div className="button-box">

                      <Link style={{ "marginBottom": "1rem" }} className="button call-to-action" title="Login" to="/user-editor">
                          Complete User Profile
                          <span className="icon-box">
                            <i className="icon"></i>
                          </span>
                      </Link>
                        {isLogin() ? (
                          <Link className="button" title="Login" to="/live">
                              Live
                          </Link>
                        ) : (
                          <Link className="button" title="Login" to="/user-login">
                              Login
                          </Link>
                        ) }
                        
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="overlay live-stream active scroll-overlay"
          id="saves"
          style={{ display: "none" }}
        >
          <div className="swiper-lazy-preloader"></div>
        </div>
        <Footer />
      </div>
    )

}