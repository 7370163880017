import React, { useContext, useEffect, useState } from "react";
import SmallHero from "../Components/SmallHero";
import StepNavigation from "./StepNavigation";
import { ProfileContext } from "../Context/ProfileContext";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { doRequest } from "../utils/doRequest";
import { cryptedUserId, decryptedUserId } from "../utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const editorConfiguration = {
	toolbar: [
	  "bold",
	  // 'italic'
	],
  };

export default function Matchmaking() {

	document.body.classList.add("backend");
  document.body.classList.add("bd-user-profile");

  const { profile } = useContext(ProfileContext);

	const [activateMatchmaking, setActivateMatchmaking ] = useState(false);
	const [description, setDescription] = useState("");
  const [userImage, setUserImage] = useState();
	const [showMenu, setShowMenu] = useState('');
  const [showphone, setShowPhone] = useState();
  const [showemail, setShowMail] = useState();
  const navigate = useNavigate();
  

	const [matchQuestions, setMatchQuestions] = useState([]);
  const [dataTitle, setDataTitle] = useState('');
  const [selectedFile, setSelectedFile] = React.useState(null);

  useEffect(() => {
    if(profile.activatematch || profile.description || profile.image ) {
      setActivateMatchmaking(profile.activatematch);
      setDescription(profile.description);
      setUserImage(profile.image);
      setShowPhone(profile.showphone);
      setShowMail(profile.showemail); 
    }
  },[profile])

  
	useEffect(() => {
		let isSubscribed = true;
      
      doRequest(
        "React",
        "/lib/storage/users/"+ decryptedUserId() +"/matchQuestions.json",
        "GET",
        {},
        true,
        (err, data) => {
          if (isSubscribed) {
            console.log(data);
            setMatchQuestions(data);
          }
        }
		);
	
		return () => {
		  isSubscribed = false;
		};
	  }, []);

	function toggle(value) {
		if (showMenu != value) {
		  setShowMenu(value);
		} else {
		  setShowMenu("");
		}
	}

  function handleFileInputChagne(e) {
    e.preventDefault();
    setSelectedFile(e.target.files[0]);
    setDataTitle("Dateiname: " + e.target.value.split( '\\' ).pop())
  }

  function handleQuestion(e) {
    
    const newState = matchQuestions.map((question,queIndex) => {
      let answers = Object.values(question.answers).map((answer) => { 
        if(parseInt(question.uid) == parseInt(e.target.getAttribute('question'))) {
          if(e.target.value.split('-')[1] == '0' && answer.uid != e.target.value && e.target.checked ) {
            return { ...answer , checked: false }; 
          }
        }
        
        if(answer.uid == e.target.value) {
            return { ...answer, checked : e.target.checked };
        } else {
      
          if(parseInt(question.uid) == parseInt(e.target.getAttribute('question')) && isNaN(answer.uid)) {
            return { ...answer, checked: false };  
          }

          return { ...answer };
        }
      })
      
      return { ...question,  answers : answers }

    });

    setMatchQuestions(newState);

  }

  function InputType({ questiontype, answer, question }) {
    
    if(answer.uid == question.uid + '-0') {
      return (
        <div className="field-item standard-radio">
           <input
            type="checkbox"
            id={`answer-${question.uid}0`}
            value={answer.uid}
            question={question.uid}
            checked={answer.checked}
            onChange={handleQuestion}
          />
          <label htmlFor={`answer-${question.uid}0`} className="hashtag-label">Not a relevant criterium for me</label>
        </div>
      )
    } else {
      return (
        <>
          <input
            type="checkbox"
            id={`answer-${question.uid}${answer.uid}`}
            value={answer.uid}
            question={question.uid}
            checked={answer.checked}
            onChange={handleQuestion}
          />
          <label htmlFor={`answer-${question.uid}${answer.uid}`}>
            {answer.answer}
            {answer.description ? (
              <span className="description">{answer.description}</span>
            ) : (
              ""
            )}
          </label>
        </>
      );
    }
  }

  function handleNext(e) {
    e.preventDefault();
    const answer = matchQuestions.map((question) => {
        return { [question.uid]: Object.values(question.answers).filter((answer) => answer.checked == true).map(({uid}) => uid) };
    });

    var data = new FormData();
    data.append("selectedFile", selectedFile);
    data.append("isjson", true);
    let params = {
      "userid": cryptedUserId(),
      "step": 4,
      "fields" : {
        "activate-m": activateMatchmaking,
        "show-phone": showphone ? 1:0,
        "show-email": showemail?1:0,
        "description" : description,
        "answer" : answer,
      }
    };

    data.append("fields", JSON.stringify(params));
    
    doRequest('Server','/api/v1/Frontend/user/updateUser','XHR', 
        data
      , true, (err, data) => {
        console.log(data);
        if(data) {
          toast.success('Profile image Remove Successfully', {hideProgressBar: false  });
          return navigate('/user-editor/summary');
        }
    })
  }

  function handlePrev(e) {
    e.preventDefault();
    const answer = matchQuestions.map((question) => {
        return { [question.uid]: Object.values(question.answers).filter((answer) => answer.checked == true).map(({uid}) => uid) };
    });

    var data = new FormData();
    data.append("selectedFile", selectedFile);
    data.append("isjson", true);
    let params = {
      "userid": cryptedUserId(),
      "step": 4,
      "fields" : {
        "activate-m": activateMatchmaking,
        "show-phone": showphone ? 1:0,
        "show-email": showemail?1:0,
        "description" : description,
        "answer" : answer,
      }
    };

    data.append("fields", JSON.stringify(params));
    
    doRequest('Server','/api/v1/Frontend/user/updateUser','XHR', 
        data
      , true, (err, data) => {
        console.log(data);
        if(data) {
          toast.success('Profile image Remove Successfully', {hideProgressBar: false  });
          return navigate('/user-editor/exhibitor');
        }
    })
  }


  function handleImageRemove(e) {
    e.preventDefault();
    if (window.confirm('Are you sure?')) {
      doRequest('Server', '/api/v1/Frontend/user/updateUser','POST', 
      {
        "userid": cryptedUserId(),
        "step": 4,
        "remove-file": 'file'
      }, true, (err, data) => {
        
        if(data) {
          toast.success('Profile image Remove Successfully', {hideProgressBar: false  });
          setUserImage('');
        }
      }) 

    }
    console.log('Remove');
  }


  return (
    <div className="base-box scroll-box" id="page-top">
      <Header hideOptions={true} />

      <div className="base-item">
        <SmallHero />

        <main className="main-content">
          <StepNavigation />

          <section className="modul backend-editor step-4">
            <div className="item-box">
              <div className="form-box">
              
                <div className="intro-box">
                  <h3>Your Profile - Matchmaking</h3>
                  <p className="infotext">
                    „Matchmaking“ allows you to connect and network with
                    interesting people at CCW.
                  </p>
                </div>

                  <div
                    className={activateMatchmaking ? "matchmaking-activate active" : "matchmaking-activate"}
                    id="activate-matchmaking"
                  >
                    <div className="field-item standard-radio">
                      <input
                        type="checkbox"
                        name="tx_user_edituser[activate-m]"
                        id="activate-m"
                        value={activateMatchmaking}
						            // onClick={(e) => setActivateMatchmaking(e.target.checked)}
                        checked={activateMatchmaking}
                        onChange={(e) => setActivateMatchmaking(e.target.checked)}
                      />
                      <label
                        htmlFor="activate-m"
                        className="checkbox-label"
                        id="activate-m-label"
                      >
                        Activate matchmaking in order to see other attendees and
                        exhibitors with profiles that match your criteria
                      </label>
                      <div className="no-matchmaking">
                        <div className="button-box">
                          <div className="field-item">
                            <button
                              className="button previous show-loader"
                              name="tx_user_edituser[saveStep4]"
                              value="prev"
                            >
                              <span className="button-label">Previous</span>
                              <span className="icon-box">
                                <i className="icon"></i>
                              </span>
                            </button>
                          </div>
                          <div className="field-item">
                            <button
                              className="button call-to-action next show-loader"
                              onClick={handleNext}
                            >
                              <span className="button-label">
                                Save &amp; complete
                              </span>
                              <span className="icon-box">
                                <i className="icon"></i>
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="matchmaking-box">
                    <div className="form-row profil-box">
                      <div className="field-item profil-image-box">
                        <div className="field-item-wrapper">
                          <label className="block-label">
                            Upload your profile image.{" "}
                            <span className="description">
                              Hint: Networking with photo is more successful.
                            </span>
                          </label>
                          <div className="upload-container">
                            <div className="image-preview">
                              <figure className="image-box">
                                {userImage ? <img src={process.env.REACT_APP_SERVER_ENDPOINT + userImage} /> : <img src="/images/icon-user.svg" />}
                              </figure>
                            </div>
                            <input
                              data-multiple-caption="{$count} files selected"
                              id="file"
                              type="file"
                              name="tx_user_edituser[file]"
                              onChange={handleFileInputChagne}
                            />
                            <label htmlFor="file" className="label-fileupload">
                              <strong>
                                Profile Image Upload
                                <div className="icon-box upload">
                                  <i className="icon"></i>
                                </div>
                              </strong>
                              <span className="ext-data-title" id="data-title" >{dataTitle}</span>
                              <span className="icon-box checkmark">
                                <i className="icon"></i>
                              </span>
                              <span className="data-title"></span>
                            </label>
                          </div>
                        </div>
                        {userImage ? (
                          <a
                          className="remove-user-file delete delete-item"
                          onClick={handleImageRemove}
                        >
                          <span className="label">Remove profile image</span>
                          <span className="icon-box">
                            <i className="icon"></i>
                          </span>
                        </a>
                        ) : ''}

                        <div className="field-item-wrapper text-editor-box">
                          <label className="block-label" htmlFor="editor">
                            Write something meaningful about yourself.{" "}
                            <span className="description">
                              What‘s your job? What are you looking for? Where
                              do you come from? What's your favorite hobby? The
                              content of this field is searched in the
                              matchmaking search. This Text can be in German or
                              in English{" "}
                            </span>
                          </label>
                          <CKEditor
                            id="editor"
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={description?description: ''}
                            onBlur={(event, editor) => {
                              setDescription(editor.getData());
                            }}
                          />
                          <div id="word-count"></div>
                        </div>
                      </div>
                      <div className="field-item questions-box">
                        <p className="info-text">
                          <strong>
                            What kind of people would you like to meet?{" "}
                          </strong>
                          <span className="description">
                            „Not a relevant criterium“ means this selection
                            criterium is disregarded
                          </span>
                        </p>

                      {matchQuestions && matchQuestions.map( ( question,index ) => (
                        <>{question.matchtext ? (
                          <div key={index} className="field-item-wrapper">
                            <label 
                            className={
                              showMenu == question.uid
                                ? "block-label selector active"
                                : "block-label selector"
                              }
                            onClick={() => toggle(question.uid)}>
                              <span>
                                {question.matchtext}
                                <span className="description">Multiple choices allowed</span>
                                <span className="icon-box"><i className="icon"></i></span>
                              </span>
                            </label>
                            <div className="form-row hashtag-box">
                            {Object.values(question.answers).length && Object.values(question.answers).map((answer, index) => (
                                <div key={index} className="field-item standard-radio">
                                    <InputType
                                      questiontype={question.questiontype}
                                      answer={answer}
                                      question={question}
                                    />
                                </div>
                            ))}
                          </div>
                        </div>
                        ) : ''}</>
                      ) )}

                      </div>
                    </div>

                    <div className="form-row addtional-info-box">
                      <div className="field-item">
                        <div className="form-row standard-radio">
                          <input
                            type="checkbox"
                            name="tx_user_edituser[show-phone]"
                            id="show-phone"
                            value={showphone}
                            checked={showphone}
                            onChange={(e) => setShowPhone(e.target.checked)}
                          />
                          <label htmlFor="show-phone" className="checkbox-label">
                            Please also show my phone number to users I have
                            accepted as contact
                          </label>
                        </div>
                        <div className="form-row standard-radio">
                          <input
                            type="checkbox"
                            name="tx_user_edituser[show-email]"
                            id="show-email"
                            value={showemail}
                            checked={showemail}
                            onChange={(e) => setShowMail(e.target.checked)}
                          />
                          <label htmlFor="show-email" className="hashtag-label">
                            Show my email in matchmaking profile to people
                          </label>
                        </div>
                      </div>
                      <div className="field-item"></div>
                    </div>

                    <div className="button-box">
                      <div className="field-item">
                        <button
                          className="button previous show-loader"
                          name="tx_user_edituser[saveStep4]"
                          value="prev"
                          onClick={handlePrev}
                        >
                          <span className="button-label">Previous</span>
                          <span className="icon-box">
                            <i className="icon"></i>
                          </span>
                        </button>
                      </div>
                      <div className="field-item">
                        <button
                          className="button call-to-action next show-loader"
                          name="tx_user_edituser[saveStep4]"
                          value="next"
                          onClick={handleNext}
                        >
                          <span className="button-label">Save &amp; complete</span>
                          <span className="icon-box">
                            <i className="icon"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
}
