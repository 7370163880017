import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { doRequest } from '../utils/doRequest'
import SmallHero from '../Components/SmallHero'

import parse from "html-react-parser";

import { decryptedUserId } from '../utils';
import Matchlinks from '../Matchmaking/Matchlinks'
import Header from '../Components/Header';
import Footer from '../Components/Footer';

export default function UserInfo() {
    let { userId } = useParams();
    let isSubscribed = true;

    const [ match, setMatch ] = useState([]);
    const [ profile, setProfile ] = useState([]);
 
    useEffect(() => {
        doRequest('React', 'server.php', 'GET', { type: 'profileDetials', user: decryptedUserId(), getUserDetialsOf: userId }, true, (err,data) => {
            if(isSubscribed) {
                setMatch(data.response.match);
                setProfile(data.response.profile);
            }
        })
        return () => (isSubscribed = false)
    },[])

  return (
    <div className="base-box fixed scroll-box" id="page-top">
        <Header />
        <div className="base-item" >
            <SmallHero />
        </div>
        <main className="main-content">
            <section className="modul filter-nav" id="filter-nav-section">
                <div className="item-box">
                    <div className="nav-box matchfilter-navi">
                        <Matchlinks />
                    </div>
                </div>
            </section>
            {profile && match && (
            <section className="modul user-detail">
                <div className="item-box">
                    <div className="small-box">
                        <h3>Get in Touch</h3>
                        <p className="info-text">Found an interesting match? Then there is just one more thing to do: Click on the orange button to start your chat. We wish you a good conversation!</p>
                        <div className="button-box">
                            <button className="button call-to-action start-chat-btn" data-id="10" data-company-id=""><span className="button-label">Start Chat</span><span className="icon-box"><i className="icon"></i></span></button>
                        </div>
                    </div>
                
                    <input type="hidden" className="rocket-chat-iframe-value" value="https://ccw.codersunlimited.com/chat" />
                    <input type="hidden" id="from" value="10" />
                    <input type="hidden" id="to" value="14" />
                        <div className="user-detail-box">
                            <div className="user-image">
                                <figure className="image-box">
                                    <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + match.image} />
                                </figure>
                                <button className="button bookmark " data-id="10"><span className="button-label">Bookmark</span> <span className="icon-box bookmarks"><i className="icon"></i></span></button>      
                            </div>

                            <div className="user-information">
                                <div className="user-information-item">
                                    <div className="user-nameset">
                                        <div className="user-nameset-info"><strong>{profile.title}  {profile.first_name} {profile.last_name}</strong></div>
                                        <div className="user-nameset-info">{profile &&  profile.position}</div>
                                        <div className="user-nameset-info"><span className="content-label">Email: </span>{profile.email}</div>
                                    </div>
                                    <div className="hashtag-box">
                                        <ul className="product-list">
                                            {match.expertises && match.expertises.map((expertise, index) => {
                                                if (expertise.checked) {
                                                    return <li key={index}>{expertise.name}</li>;    
                                                }
                                            })}
                                        </ul>
                                    </div>      
                                    <div className="text-box">
                                        <h5>About Me</h5>
                                        {match.description && parse(match.description)}
                                    </div>
                                    
                                    <div className="user-status">
                                    </div>
                                </div>
                            </div>

                            <div className="score-box match">            
                                <div className="score-box match">
                                        <span className="match">Match</span><span className="icon-box"><i className="icon"></i></span>
                                </div>
                            </div>
                        </div>
                    <div className="button-box small-box">
                        <button className="button call-to-action start-chat-btn" data-id="10" data-company-id=""><span className="button-label">Start Chat</span><span className="icon-box"><i className="icon"></i></span></button>                  
                    </div>
                </div>
            </section>
            )}
        </main>
        <Footer />
        </div>
  )
}
