import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function StepNavigation() {

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/")[2];

  return (
    <section className="modul step-navigation">
      <div className="item-box">
        <div className="step-box">
          <div className={splitLocation ==  "personal"  || splitLocation == undefined ? "step-item active" : "step-item"} >
            <Link to={"/user-editor/personal"}>
              <span>Personal Data</span>
            </Link>
          </div>

          <div className={splitLocation ==  "professional" ? "step-item active" : "step-item"} >
            <Link to={"/user-editor/professional"}>
              <span>Professional Data</span>
            </Link>
          </div>

          <div className={splitLocation ==  "exhibitor" ? "step-item active" : "step-item"}>
            <Link to={"/user-editor/exhibitor"}>
              <span>Exhibitor Data</span>
            </Link>
          </div>

          <div className={splitLocation ==  "matchmaking" ? "step-item active" : "step-item"}>
            <Link to={"/user-editor/matchmaking"}>
              <span>Matchmaking</span>
            </Link>
          </div>

          <div className={splitLocation ==  "summary" ? "step-item active" : "step-item"}>
            <Link to={"/user-editor/summary"}>
              <span>Summary</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
