import * as React from "react";
import { Link } from "react-router-dom";
import { useContext } from "react";
import MyAgendaContext from "../Live/Context/myAgendaContext";
import { AuthContext } from "../Context/AuthContext";

import { decryptedUserId, isLogin } from '../utils';
import { doRequest } from "../utils/doRequest";
import { ProfileContext } from "../Context/ProfileContext";
import { TopicsContext } from "../Context/TopicsContext";

export default function Header({setIsLoggedInUser, hideOptions}) {
    const authContext = useContext(AuthContext);
    
    const { topics, setTopics } = useContext(TopicsContext);
    const { profile, setProfile } = useContext(ProfileContext);

    const { userAgenda } =  useContext(MyAgendaContext);
    const [ chatActive, setChatActive] = React.useState('');

    function HandleLogout() {
        authContext.logout();
    }

    React.useEffect(() => {
        let isSubscribed = true;
        
            doRequest(
                'React',
                'lib/storage/content/Topics.json',
                'GET', 
                {},
                false,
                (err,data) => {
                    if (isSubscribed) {
                        setTopics(data);
                    }
                }
            );

            doRequest('React', '/lib/storage/users/' + decryptedUserId() + '/profile.json', 'GET', { }, true, (err,data) => {
                if (isSubscribed) {
                    setProfile(data);
                }
            })
        

        return () => (isSubscribed = false)
    },[])

    // $(document).on('submit', '#topic-search-form', function(e) {
    //     e.preventDefault();

    //     $('#topic-navi, #user-profile').removeClass('active');
    //     $('.match-header').show();
    //     $('.live-header').hide();
    //     $('.hero-content').addClass('small-hero').removeClass('full-size');
    //     $('.main-content section').hide();

    //     $('#saves').show();
    //     var topicsArr = [];
    //     $('.filter-topics-live').each(function(){
    //         if ($(this).prop('checked')) {
    //             topicsArr.push($(this).val());
    //         }
    //     })

    //     $.ajax({
    //         url: process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + '?type=1234512345',
    //         type: 'POST',
    //         data: {
    //             tx_ipswnews_newssearch : {
    //                topic : topicsArr,
    //             }
    //         },
    //         success: function(res) {
    //             console.log(res);
    //             $('#searchResultPlaceHolder').html(res);
    //             $('#searchResultPlaceHolder').show();
    //             $(".bg-image.live-header").show();
    //             $('.hero-content.small-hero').show();
    //             // $('html, body').animate({
    //             //     scrollTop: $('#search-result').offset().top-100
    //             // }, 500);
    //             //$('#searchResultPlaceHolder').remove();
    //             $('#saves').hide();
    //         }
    //     })

    // })

    if(!isLogin() || hideOptions) {
        return (
            <header id="masthead" className="site-header cf" role="banner">
                <div className="branding">
                    <div className="item-box">
                        <Link className="logo" title="IP Service World" to="/"><span></span></Link>
                        <div className="meta-box"></div>
                    </div>
                </div>
            </header>
        )
    }

    return (
       
        <header id="masthead" className="site-header cf" key={'header'} role="banner">
        <div className="branding">
            <div className="item-box">
                <Link className="logo" title="CU at Conf" to="/live"><span></span></Link>
            <div className="meta-box">
                <div className="topic-navi">
                <button className="topic-filter button" id="topic-navi">
                    <span className="topic-label">Search for Topic</span>
                    <span className="icon-box search"><i className="icon"></i></span>
                </button>
                <div className="filter-box filters">
                    <form
                    id="topic-search-form"
                    action="/search#search-result"
                    method="post"
                    >
                    <div></div>
                    <div className="form-row">
                        {topics.length && topics.map((topic) => {
                            return (
                                <div key={topic.uid} className="field-item standard-radio">
                                    <input
                                        type="checkbox"
                                        className="filter-topics-live"
                                        name="tx_ipswnews_newssearch[topic][]"
                                        id={`topic-${topic.uid}`}
                                        value={topic.uid}
                                    />
                                    <label htmlFor={`topic-${topic.uid}`} className="checkbox-label">{topic.name}</label>
                                </div>
                            )                            
                        })}
                       

                    </div>
                    <div className="button-box">
                        <button
                            className="button call-to-action"
                            name="tx_ipswnews_newssearch[search]"
                            value="search"
                        >
                            <span>Search</span>
                            <span className="icon-box search">
                                <i className="icon"></i>
                            </span>
                        </button>
                    </div>
                    </form>
                </div>
                </div>

                <div className="matchmaking-navi">
                    <Link hash="#agenda" to="/live" className="button agenda">
                        <span className="matchmaking-label">my Agenda</span>
                        <span className="icon-box"><i className="icon agenda-counter"> {userAgenda.length} </i></span>
                    </Link>
                    

                    <button className="notification button" id="notification-opener" onClick={() => setChatActive('active')}>
                        <span className="icon-box chat">
                        <i className="icon"></i>
                        </span>
                        <span className="matchmaking-counter">0</span>
                        <input type="hidden" id="currentRequests" value="0" />
                    </button>
                    <Link className="button call-to-action matchmaking-link" title="Matchmaker" to="/matchmaking">
                        <span className="matchmaking-label">Matchmaker</span>
                        <span className="icon-box"><i className="icon"></i></span>
                    </Link>
                </div>

                <div className="user-box">
                    {profile && profile.image ? (
                        <button
                            className="user-profile"
                            id="user-profile"
                            style={{ 'backgroundImage': "url('"+ process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + profile.image +"')" }}
                            >
                            <span className="user-image icon-box"></span>
                        </button>            
                    ) : (
                        <button
                            className="user-profile"
                            id="user-profile"
                            style={{ 'backgroundImage': "url('/images/icon-user.svg')" }}
                        >
                            <span className="user-image icon-box"></span>
                        </button>        
                    ) }

                
                <div className="user-profile-navi">
                    <Link className="my-contacts-link" to='/my-contacts'>
                        My Contacts
                        <span className="icon-box"><i className="icon"></i></span>
                    </Link>

                    <a href="/live#agenda" className="agenda-link">
                        My Agenda
                        <span className="icon-box"><i className="icon"></i>
                        </span>
                    </a>

                    <Link to="/user-editor">
                        Edit Profile
                        <span className="icon-box"><i className="icon"></i></span>
                    </Link>
                    
                    <Link to='/exhibitor-editor'>
                        Edit Exhibitor Data
                        <span className="icon-box"><i className="icon"></i></span>
                    </Link>
                
                    <a href="#!" className="system-check">
                        System Check
                        <span className="icon-box"><i className="icon"></i></span>
                    </a>
                    <a href="#!" onClick={HandleLogout}>
                        Logout
                        <span className="icon-box"><i className="icon"></i></span>
                    </a>
                </div>
                </div>
            </div>

            <div
                className={`overlay chat-notifications scroll-overlay ${chatActive}`}
                id="matchmaking-overlay"
            >
                <div className="item-box">
                <button className="close close-overlay" onClick={() => setChatActive('')}>
                    <span className="icon-box close"><i className="icon"></i></span>
                </button>
                <div className="overlay-container">
                    <div className="iframe-box">
                    {/* <iframe
                        id="overlay-iframe"
                        src="https://cuatconference-dev.codersunlimited.com/contact-request"
                        frameBorder="0"
                        scroll="noscroll"
                    ></iframe> */}
                    <iframe
                        title="Chat Iframe"
                        id="overlay-iframe"
                        src="/Chat"
                        frameBorder="0"
                        scroll="noscroll"
                    ></iframe>
                    </div>
                </div>
                </div>
            </div>

            {/* <div className="chat-window" style="display: none">
                <iframe
                allow="camera;microphone"
                className="rocket-chat-iframe"
                frameborder="0"
                ></iframe>
                <div className="close-chat">Close</div>
            </div> */}
            </div>
        </div>
        </header>
    );
}