import { Link } from "react-router-dom";

export default function Hero() {
    return (
        <section className="hero-content full-size">
			<div className="bg-image match-header" style={{'display': 'none'}}>
				<figure className="image-box">
					<img srcSet="images/bg-small-790.jpg 760w,
						images/bg-small-1080.jpg 1080w,
						images/bg-small.jpg 1600w" sizes="(max-width: 1680px) 100vw, 1680px" src="images/bg-small.jpg" />
				</figure>
			</div>

			<div className="bg-image live-header">
				<figure className="image-box">
					<img srcSet="images/bg-live-790.jpg 790w,
											 images/bg-live-1080.jpg 1080w,
											 images/bg-live.jpg 1680w" sizes="(max-width: 1680px) 100vw, 1680px" src="images/bg-live.jpg" />
				</figure>
			</div>

			<div className="item-box live-header">
				<div className="content-box">
                        <figure className="image-box">
                            <img src="images/cu-at-conference.png" />
                        </figure>
                        <h1>Use our Matchmaker to find your Business Partner!</h1>
                        <div className="button-box">
							<Link to="/matchmaking" className="button call-to-action matchmaking-link" >
								<span className="matchmaking-label">Join Now!</span><span className="icon-box"><i className="icon"></i></span>
							</Link>
                    </div>
                </div>
			</div>
		</section>
    );
}