import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { doRequest } from "../utils/doRequest";

const axios = require('axios');

export default function NextLiveStreamUpOnStage () {

    // $(function () {
    //     setInterval(function () {
    //       $.ajax({
    //         url: "/?type=456456",
    //         type: "POST",
    //         success: function (res) {
    //           $(".stage-stream").html(res);
    //         },
    //       });
    //     }, 10000);
    // });
    const [ liveStreams , setLiveStream ] = useState([]);

    useEffect(() => {
        let isSubscribed = true;

        doRequest(
          'React',
          'lib/storage/content/livestreams.json',
          'GET', 
          {},
          false,
          (err,data) => {
              if (isSubscribed) {
                setLiveStream(data);
              }
          }
        );

        return () => (isSubscribed = false)
    },[]);
    
    function StreamName({stream}) {

      let stageName = '';

      if (stream.stage == 1 || stream.stage == 2 || stream.stage == 3 ) {stageName = process.env.REACT_APP_PROGRAM_TAB_1}
      if (stream.stage == 4 || stream.stage == 5 || stream.stage == 6) {stageName = process.env.REACT_APP_PROGRAM_TAB_2}
      if (stream.stage == 7 || stream.stage == 8 || stream.stage == 9) {stageName = process.env.REACT_APP_PROGRAM_TAB_3}

      return stageName;
    }

    

    return (
        <section key="livestream-container" className="modul next-on-stage" id="livestream-container">
            <div className="item-box">
                <h5 className="modul-title">Next live Stream up on Stage</h5>
                <div className="events-box tage-stream">

                { liveStreams && liveStreams.map((stream) => (

                    // {livestream.stage} == '1' || {livestream.stage} == '2'

                    <div key={stream.stage} className={`flex-item narrow-item ${(stream.stage == 1 || stream.stage == 2 )? 'tradeshow': ''} ${(stream.stage == 3 || stream.stage == 4 )? 'tradeshow': ''} ${(stream.stage == 5 || stream.stage == 6 )? 'conference': ''} `}>
                    <div className="event-item">
                      <figure className="image-box">
                        <img src={`${process.env.REACT_APP_SERVER_ENDPOINT}${stream.previewimage}`} />
                      </figure>
                      <div className="events-info">
                        <div className="stage-box"><StreamName stream={stream} /></div>
                        <div className="time-box"><strong>{stream.starttimestamp}</strong></div>
                        <div className="stream-title-box">
                          <h4>{stream.title}<br /></h4>
                        </div>
                        <div className="speaker-box">
                          <p className="no-margin">
                            <strong> </strong><br /><strong> {stream.companyId.companyname}</strong>
                          </p>
                        </div>
                        <div className="text-box">
                            {parse(stream.description)}
                        </div>
                        <div className="product-overview-box">
                          <ul className="product-list">
                          {stream && stream.topic && stream.topic.map((topicName) => (
                              <li>{topicName.name}</li>
                          ))}
                          </ul>
                        </div>
                        <button className="button-box open-info-box" data-id="1">
                          <span className="icon-box open"><i className="icon"></i></span>
                        </button>
                        <div className="events-info-box">
                          <button className="close-info-box" data-id="1">
                            <span className="icon-box close"><i className="icon"></i></span>
                          </button>
                          <h3>{stream.title}</h3>
                          {parse(stream.longtext)}
                        </div>
                      </div>
                      <div className="button-box">
                        <a
                          href="#"
                          className="button call-to-action live-stream-opener recent-stream"
                          data-company-id={stream.companyId.companyid}
                          data-uid={stream.uid} >
                                <span className="button-label">Start Live Stream</span>
                                <span className="icon-box"><i className="icon"></i>
                                </span>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}

                </div>
            </div>

            </section>

    )
}