import { Link } from "react-router-dom";

export default function FindYourBuisnessPartner () {

    return (
    <section className="modul modul-bg-image-text">
        <div className="item-box">
            <div className="text-box">                
                    <h3>Find your Business Partner here</h3>
                    <p>Use our matchmaker-tool and start networking now.</p>
                    {/* Commented the class for stopping reload matchmaking-link */}
                    <Link className="button call-to-action " to={'/matchmaking'} >
                        <span className="matchmaking-label">Visit our Matchmaker!</span><span className="icon-box"><i className="icon"></i></span>
                    </Link>
            </div>
        </div>
        <div className="bg-image">
                <figure className="image-box">
                {/* <!-- Output Media File Form the typo3 Backend
                ATTENTION: this is a BG image biggest image should be 1920px, second 960px and than560px in width 	--> */}
                <img srcSet={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}/fileadmin/user_upload/live-dummy.jpg 360w,
                             ${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}/fileadmin/user_upload/live-dummy.jpg 960w" sizes="(max-width: 1980px) 100vw, 1980px" src="${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}/fileadmin/user_upload/live-dummy.jpg`} alt="Imagedescription: Window" />
                </figure>
        </div>
    </section>
    )
}