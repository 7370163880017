import axios from "axios";
import $ from 'jquery';
import { useEffect, useState } from "react";

export default function SponserSection () {
    let isSubscribed = true;

    // $(document).ready(function(){
    //     if($.fn.imagesLoaded) {
    //         var $grid = $('.company-masonry').imagesLoaded( function() {
    //             $grid.isotope({
    //                 itemSelector: '.company-masonry-item',
    //                 percentPosition: true,
    //                 masonry: {
    //                     columnWidth: 50,
    //                     gutter: 10
    //                 }
    //             });
    //         });
    //     }
    // });

    const [ sponsors,setSponsors ] = useState([]);

    useEffect(() => {
		axios.get(process.env.REACT_APP_ENDPOINT + 'lib/storage/content/sponsors.json', {
			headers: { 
				'Authorization': 'Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh'
			},
			auth: {
			  username: 'cuatconference',
			  password: 'relaunch'
			}
		  })
		.then(res=>{
            if (isSubscribed) {
			setSponsors(res.data);
            }
		})
		.catch(err=>{
			console.log(err);
		})

        return () => (isSubscribed = false)
	}, []);

    function Sponsors({sponsor}) {
        
        let thumbsize = '';
        if(sponsor.thumbsize == 1) { thumbsize = 'thumb-small-xx'; }
        if(sponsor.thumbsize == 2) { thumbsize = 'thumb-small-x'; }
        if(sponsor.thumbsize == 3) { thumbsize = 'thumb-small'; }
        if(sponsor.thumbsize == 4) { thumbsize = 'thumb'; }

        return (
            <div key={sponsor.brandname} className={`company-masonry-item ${thumbsize}`} >
                <a href={sponsor.link} target="'_blank'" data-id=""></a>
                <figure className="image-box exhibitor-category">
                <img
                    alt={sponsor.brandname}
                    // src={sponsor.logo}
                    src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}${sponsor.logo}`}
                />
                </figure>
            </div>
        )
        
    }


    return (
        <div className="tx-exhibitor">
            <section className="modul gallery-box" id="sponsor-container">
                <div className="item-box company-live-ajax">
                <h5>Thanks to our Sponsors</h5>
                <div
                    className="sorting-box company-masonry"
                    id="company-box"
                >
                    <div className="sorting-box-wrapper">
                        {sponsors && sponsors.map((sponsor, index) => <Sponsors key={index} sponsor={sponsor} />)}
                    </div>
                </div>

                <script></script>
                </div>
            </section>
        </div>
    )
}