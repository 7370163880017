import React, { PureComponent } from "react";

import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/navigation';
import "swiper/css/bundle";
import 'swiper/css/virtual';

import { homepageSponsors } from '../Constants';

import { Navigation, Autoplay, Lazy, Virtual } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";


export default class ThanksToSponsersSlider extends PureComponent {


    // new Swiper("#slider-3", {
    //   navigation: {
    //     nextEl: ".next-3",
    //     prevEl: ".prev-3",
    //   },
    //   speed: 600,
    //   loop: true,
    //   lazy: true,
    //   lazy: {
    //     loadPrevNext: true,
    //     loopPreventsSlide: true,
    //     loadPrevNextAmount: 1,
    //   },
    //   autoplay: {
    //     delay: 2500,
    //     disableOnInteraction: false,
    //   },
    //   autoHeight: false,
    //   slidesPerView: 2,
    //   slidesPerGroup: 1,
    //   spaceBetween: 10,
    //   touchRatio: 0,
    //   watchSlidesVisibility: true,
    //   breakpoints: {
    //     // when window width is <= 320px
    //     560: {
    //       slidesPerView: 3,
    //       slidesPerGroup: 1,
    //     },
    //     960: {
    //       slidesPerView: 5,
    //       slidesPerGroup: 1,
    //     },
    //     1080: {
    //       slidesPerView: 6,
    //       slidesPerGroup: 1,
    //     },
    //     1280: {
    //       slidesPerView: 7,
    //       slidesPerGroup: 1,
    //     },
    //   },
    // });

  render() {
    return (
      <div className="item-box">
        <header className="modul-header">
          <h3>Thanks to our Sponsors</h3>
        </header>
        <div className="slider-center-box">
          <Swiper
            preloadImages={false}
            modules={[Navigation, Autoplay, Lazy]}
            lazy={true}
            spaceBetween={10}
            slidesPerView={2}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // when window width is <= 320px
              560: {
                slidesPerView: 3,
                slidesPerGroup: 1,
              },
              960: {
                slidesPerView: 5,
                slidesPerGroup: 1,
              },
              1080: {
                slidesPerView: 6,
                slidesPerGroup: 1,
              },
              1280: {
                slidesPerView: 7,
                slidesPerGroup: 1,
              },
            }}
            className="mySwiper"
          >
            {homepageSponsors.map((slideContent, index) => (
              <SwiperSlide key={slideContent} virtualIndex={index}>
                <figure className="image-box exhibitor-category platin">
                  <img data-src={slideContent} className="swiper-lazy" />
                </figure>
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-prev prev-3">
            <span className="icon-box">
              <i className="icon"></i>
            </span>
          </div>
          <div className="swiper-button-next next-3">
            <span className="icon-box">
              <i className="icon"></i>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
