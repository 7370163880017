import { useContext, useState } from "react";
import MyAgendaContext from "./Context/myAgendaContext";
import ProgramContext from "./Context/programContext";
import parse from "html-react-parser";
import axios from "axios";
import { useEffect } from "react";

import {decryptedUserId} from "../utils";

export default function AgendaSection() {
  const { userAgenda, setUserAgenda } = useContext(MyAgendaContext);
  const { programs } = useContext(ProgramContext);
  const [userId, setUserId] = useState(0);
  const [showAll, setShowAll] = useState(0);
  const [limit, setLimit] = useState(3);

  useEffect(() => {
    setUserId(decryptedUserId());    
  }, []);

  function toggleMyagenda(e) {
    axios
      .get(process.env.REACT_APP_ENDPOINT + "server.php", {
        headers: {
          Authorization: "Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh",
        },
        auth: {
          username: "cuatconference",
          password: "relaunch",
        },
        params: {
          eventId: e.target.value,
          type: "myagenda",
          user: userId,
          checked: false,
        },
      })
      .then((res) => {
        axios
          .get( process.env.REACT_APP_ENDPOINT +  "lib/storage/users/" + userId + "/myagenda.json", {
            headers: {
              Authorization:
                "Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh",
            },
            auth: {
              username: "cuatconference",
              password: "relaunch",
            },
            params: {
              v: process.env.REACT_APP_V,
            },
          })
          .then((res) => {
            if (res.data.eventID) {
              setUserAgenda(res.data.eventID);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function ItemHTML({ streamItem }) {
    let stageName = "";

    if (
      streamItem.stage === 1 ||
      streamItem.stage === 2 ||
      streamItem.stage === 3
    ) {
      stageName = process.env.REACT_APP_PROGRAM_TAB_1;
    }
    if (
      streamItem.stage === 4 ||
      streamItem.stage === 5 ||
      streamItem.stage === 6
    ) {
      stageName = process.env.REACT_APP_PROGRAM_TAB_2;
    }
    if (
      streamItem.stage === 7 ||
      streamItem.stage === 8 ||
      streamItem.stage === 9
    ) {
      stageName = process.env.REACT_APP_PROGRAM_TAB_3;
    }


    if (!programs) {
      return <h1>Program Section Loading....</h1>;
    }
    

    return (
      <div className="personal-event tradeshow" data-id="1">
        <div className="accordion-title">
          <div className="personal-event-stageinfo">
            <div className="stage-box">{stageName}</div>
            <div className="time-box">
              <strong> {streamItem.savedDate}</strong>
            </div>
          </div>
          <div className="personal-event-title">
            <div className="item-wrapper">
              <h3>{streamItem.title}</h3>
            </div>
          </div>
          <div className="delete-aganda-item">
            <input
              type="checkbox"
              checked=""
              className="event-hall-check"
              name={`event-hall-${streamItem.eventId}`}
              id={`event-hall-${streamItem.eventId}`}
              value={streamItem.eventId}
              onChange={(e) => toggleMyagenda(e)}
            />
            <span className="icon-box delete">
              <i className="icon"></i>
            </span>
          </div>
          <span className="icon-box arrow">
            <i className="icon"></i>
          </span>
        </div>

        <div className="accordion-content">
          <div className="personal-event-info">
            <div className="text-box">
              <div className="speaker-box">
                <p className="no-margin">
                  <strong className="speaker-name">
                    {streamItem.firstname}
                  </strong>
                  <span className="speaker-position"></span>
                  <strong className="company-name">{streamItem.company}</strong>
                </p>
              </div>

              {parse(streamItem.description)}

              <ul className="product-list">
                {streamItem.topic &&
                  streamItem.topic.map((topic, index) => <li key={index}>{topic}</li>)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function ItemStream1({ itemId }) {
    if(programs.stream1) {
      return programs.stream1?.map((program) => {
        return Object.keys(program).map((val) => {
          return program[val]?.map((streamItem) => {
            if (streamItem.eventId === itemId) {
              return <ItemHTML key={1} streamItem={streamItem} />;
            }
          });
        });
      });
    } else {
      return <></>;
    }
  }

  function ItemStream2({ itemId }) {
    if(programs.stream2) {
      return programs.stream2?.map((program) => {
        return Object.keys(program).map((val) => {
          return program[val]?.map((streamItem) => {
            if (streamItem.eventId == itemId) {
              return <ItemHTML key={2} streamItem={streamItem} />;
            }
          });
        });
      });
    } else {
      return <></>;
    }
  }

  function ItemStream3({ itemId }) {
    if(programs.stream3) {
      return programs.stream3?.map((program) => {
        return Object.keys(program).map((val) => {
          return program[val]?.map((streamItem) => {
            if (streamItem.eventId == itemId) {
              return <ItemHTML key={3} streamItem={streamItem} />;
            }
          });
        });
      });
    } else {
      return <></>;
    }
  }

  function showAllEvents() {
    setLimit(userAgenda.length);
    setShowAll(1);
  }

  return (
    <section className="modul personal-events" id="agenda-container">
      <div className="jumppoint" id="agenda"></div>
      {userAgenda.length != 0 && (
        <div className="item-box">
          <h5>My Agenda</h5>
          <div className="personal-events-box">
            <input type="hidden" className="totalStream" value="3" />
            {userAgenda && programs &&
              userAgenda.length &&
              userAgenda
                .slice(0, limit)
                ?.map((itemId, index) => <ItemStream1 key={index} itemId={itemId} />)}
            {userAgenda &&
              userAgenda.length &&
              userAgenda
                .slice(0, limit)
                ?.map((itemId, index) => <ItemStream2 key={index} itemId={itemId} />)}
            {userAgenda &&
              userAgenda.length &&
              userAgenda
                .slice(0, limit)
                ?.map((itemId, index) => <ItemStream3 key={index} itemId={itemId} />)}
          </div>
          {!showAll && userAgenda.length > 3 && (
            <div className="button-box">
              <button
                className="button open-all"
                id="personal-events-opener"
                onClick={(e) => showAllEvents(e)}
              >
                <span className="button-label">Show All</span>
                <span className="icon-box arrow">
                  <i className="icon"></i>
                </span>
              </button>
            </div>
          )}

          <div className="button-box">
            <a
              className="button create-pdf"
              id="create-agenda-pdf"
              href="/user-info?tx_user_userinfo%5Baction%5D=createPdf&amp;tx_user_userinfo%5Bcontroller%5D=User&amp;cHash=a704c49a09b076add706f8c08bd9836f"
            >
              <span className="button-label">Create PDF from my Agenda</span>
              <span className="icon-box">
                <i className="icon"></i>
              </span>
            </a>
          </div>

          <input type="hidden" className="agendaLoad" value="3" />
        </div>
      )}
    </section>
  );
}
