export default function FirstView () {
    return (
        
            <section className="modul first-view-box">
            <div className="item-box">
            <h5>WELCOME TO THE DIGITAL CCW PLATFORM</h5>

                <div className="first-view">
                    <div className="first-view-item">
                    <div className="item-wrapper">
                        <figure className="image-box">
                        <img
                            src="images/icon-calendar.svg"
                            width="150"
                            height="150"
                        />
                        </figure>
                        <h5>My Agenda</h5>
                        <p>Create your own personalized Agenda</p>
                        <div className="button-box">
                            <a href="#" className="button">
                                <span className="button-label">more</span>
                                <span className="icon-box"><i className="icon"></i></span>
                            </a>
                        </div>
                    </div>
                    </div>

                    <div className="first-view-item">
                    <div className="item-wrapper">
                        <figure className="image-box">
                        <img
                            src="images/icon-handshake.svg"
                            width="150"
                            height="150"
                        />
                        </figure>
                        <h5>Matchmaker</h5>
                        <p>
                        Find and chat with business partners with our Matchmaker at CCW
                        </p>
                        <div className="button-box">
                        <a href="#" className="button"
                            ><span className="button-label">more</span
                            ><span className="icon-box"><i className="icon"></i></span
                        ></a>
                        </div>
                    </div>
                    </div>

                    <div className="first-view-item">
                    <div className="item-wrapper">
                        <figure className="image-box">
                        <img
                            src="images/icon-topicsearch.svg"
                            width="150"
                            height="150"
                        />
                        </figure>
                        <h5>Search for Topics</h5>
                        <p>
                        Filter all content of CCW and its exhibitors according to your
                        interests
                        </p>
                        <div className="button-box">
                        <a href="#" className="button"
                            ><span className="button-label">more</span
                            ><span className="icon-box"><i className="icon"></i></span
                        ></a>
                        </div>
                    </div>
                    </div>

                    <div className="first-view-item">
                    <div className="item-wrapper">
                        <figure className="image-box">
                        <img
                            src="images/icon-companyspace.svg"
                            width="150"
                            height="150"
                        />
                        </figure>
                        <h5>Company Spaces</h5>
                        <p>Explore the Company Spaces of our exhibitors</p>
                        <div className="button-box">
                        <a href="#" className="button"
                            ><span className="button-label">more</span
                            ><span className="icon-box"><i className="icon"></i></span
                        ></a>
                        </div>
                    </div>
                    </div>

                    <div className="first-view-item">
                    <div className="item-wrapper">
                        <figure className="image-box">
                        <img
                            src="images/icon-faqmovie.svg"
                            width="150"
                            height="150"
                        />
                        </figure>
                        <h5>Quick Platform Tour</h5>
                        <p>Please find our tutorials for using this unique platform</p>
                        <div className="button-box">
                        <a href="#" className="button"
                            ><span className="button-label">more</span
                            ><span className="icon-box"><i className="icon"></i></span
                        ></a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>
    )
};