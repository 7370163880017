import * as React from "react";
import {
  Routes,
  Route,
  Outlet,
  BrowserRouter as Router,
} from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthContextProvider, {AuthContext} from "./Context/AuthContext";
import BookmarkContextProvider,{BookmarkContext} from "./Context/BookmarkContext";
import { OverlaysProvider } from "./Context/overlayContext";

import LivePage from "./Live/LivePage";
import ExhibitorLogin from "./Login/ExhibitorLogin";
import UserLogin from "./Login/UserLogin";
import UserEditor from "./UserEditor";

import EditorProfessional from './UserEditor/Professional';
import EditorExhibitor from './UserEditor/Exhibitor';
import EditorMatchmaking from './UserEditor/Matchmaking';
import EditorSummary from './UserEditor/Summary';


import Matchmaking from "./Matchmaking/Matchmaking";
import MyContacts from "./Matchmaking/MyContacts";
import Bookmarks from "./Matchmaking/Bookmarks";

import UserInfo from './Live/UserInfo';
import StreamVideo from "./Live/StreamVideo";
import CompanyDetail from "./Live/CompanyDetail";

import PublicPage from "./Login/PublicPage";
// import Chat from "./Chat/Chat";
import RegistrationPage from "./Login/RegistrationPage";
import Overlays from "./Components/Overlays";
import ProfileContextProvider from "./Context/ProfileContext";
import TopicsContextProvider from "./Context/TopicsContext";
import UnAuthorizedPage from "./Login/UnAuthorizedPage";

function PrivateRouter() {
  return (
    <div>
      <Routes>
        <Route element={<Layout />}>
          <Route exact path="/" element={<PublicPage />} />
          <Route exact path="/live" element={<LivePage />} />
          <Route exact path="/matchmaking" element={<Matchmaking />} />
          <Route exact path="/userinfo/:userId" element={<UserInfo />} />
          <Route exact path="/bookmarks" element={<Bookmarks />} />
          <Route exact path="/my-contacts" element={<MyContacts />} />
          <Route exact path="/company/:cmpName" element={<CompanyDetail />} />
          <Route exact path="/user-login" element={<UserLogin />} />

          <Route exact path="/user-editor" element={<UserEditor />} />
          <Route exact path="/user-editor/personal" element={<UserEditor />} />
          <Route exact path="/user-editor/professional" element={<EditorProfessional />} />
          <Route exact path="/user-editor/exhibitor" element={<EditorExhibitor />} />
          <Route exact path="/user-editor/matchmaking" element={<EditorMatchmaking />} />
          <Route exact path="/user-editor/summary" element={<EditorSummary />} />
                   
          
          {/* <Route exact path="/chat" element={<Chat />} /> */}
          <Route path="/*" element={<h1>Invalid Url</h1>} />
        </Route>
      </Routes>
    </div>
  );
}

function PublicRouter() {
  return (
    <div>
        <Routes>
            <Route element={<Layout />}>
              <Route exact path="/" element={<PublicPage />} />
              <Route exact path="/user-login" element={<UserLogin />} />
              <Route exact path="/registration" element={<RegistrationPage />} />
              <Route exact path="/exhibitor-login" element={<ExhibitorLogin />} />
              <Route path="/*" element={<UnAuthorizedPage />} />
            </Route>
        </Routes>
    </div>
  );
}


function Layout() {
  const [showOverlay, setShowoverlay] = React.useState('');
  return (
    <>
        <OverlaysProvider value={{ showOverlay, setShowoverlay }}>
          <ToastContainer autoClose={3000} hideProgressBar />
          <Outlet />
          <Overlays />
        </OverlaysProvider>
    </>
  );
}

function SwitchRouter() {
  const authContext = React.useContext(AuthContext);
  return (authContext.isAuth ? <PrivateRouter /> : <PublicRouter />);
}

export default function App() {
  return (
    <AuthContextProvider>
      <ProfileContextProvider>
        <TopicsContextProvider>
          <BookmarkContextProvider>
            <Router>
              <SwitchRouter />
            </Router>
          </BookmarkContextProvider>
        </TopicsContextProvider>
      </ProfileContextProvider>
    </AuthContextProvider>
  );
}