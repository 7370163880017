export default function Footer () {
    return (
        <footer id="colophon" className="site-footer" role="contentinfo">
			<a href="#page-top" className="totop"><span className="icon-box arrow"><i className="icon"></i></span></a>
			<section className="footer-meta-box">
				{/* If you want to implement a background image use this
						<div className="bg-image">
								<figure className="image-box">
									<img srcset="/typo3conf/ext/tsbasetemplate/Resources/Public/images/bg-live-790.jpg 790w,
															 /typo3conf/ext/tsbasetemplate/Resources/Public/images/bg-live-1080.jpg 1080w,
 															 /typo3conf/ext/tsbasetemplate/Resources/Public/images/bg-live.jpg 1680w"
								  sizes="(max-width: 1680px) 100vw, 1680px" src="/typo3conf/ext/tsbasetemplate/Resources/Public/images/bg-live.jpg">
								</figure>
						</div>
			    */}

                    <div className="item-box">
                        <div className="footer-text-box">
                                <h4>Organizer</h4>
                                <a href="https://codersunlimited.com/" className="footer-logo" rel="noopener noreferrer"><img src="/images/logo-invert.svg" /></a>
                                <p>Education for the best. Since 1989, executives from all areas of the company have placed their trust in the high standard of our seminars, conferences and congresses. Benefit from an optimal connection of knowledge transfer and networking.</p>
                        </div>
                        <div className="footer-social-media-box">
                            <ul className="social-links">

                            <li className="facebook-item">
                                    <a href="https://facebook.com/" title="Facebook" target="_blank" rel="noopener noreferrer">
                                    <span className="icon-box svg-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M19 6h5V0h-5c-3.86 0-7 3.14-7 7v3H8v6h4v16h6V16h5l1-6h-6V7c0-.542.458-1 1-1z"></path></svg>
                                    </span><span className="icon-label">facebook</span>
                                    </a>
                                </li>
                            <li className="twitter-item">
                                    <a href="https://twitter.com/" title="Twitter" target="_blank" rel="noopener noreferrer">
                                    <span className="icon-box svg-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M32 7.075a12.907 12.907 0 0 1-3.77 1.03 6.597 6.597 0 0 0 2.888-3.63c-1.27.75-2.675 1.3-4.17 1.594A6.554 6.554 0 0 0 22.156 4a6.563 6.563 0 0 0-6.563 6.563c0 .512.056 1.012.17 1.494A18.629 18.629 0 0 1 2.23 5.195c-.563.97-.887 2.1-.887 3.3a6.555 6.555 0 0 0 2.92 5.463 6.529 6.529 0 0 1-2.976-.82v.082a6.566 6.566 0 0 0 5.27 6.437 6.581 6.581 0 0 1-2.969.112 6.588 6.588 0 0 0 6.13 4.562 13.167 13.167 0 0 1-8.155 2.813c-.53 0-1.05-.03-1.57-.094A18.571 18.571 0 0 0 10.064 30c12.075 0 18.68-10.005 18.68-18.68 0-.287-.005-.57-.018-.85a13.249 13.249 0 0 0 3.275-3.394z"></path></svg>
                                    </span><span className="icon-label">twitter</span>
                                    </a>
                                </li>
                            <li className="instagram-item">
                                <a href="https://instagram.com/" title="Instagram" target="_blank" rel="noopener noreferrer">
                                    <span className="icon-box svg-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M16 2.88c4.275 0 4.78.02 6.462.095 1.563.07 2.406.33 2.97.55.743.288 1.28.638 1.836 1.194a5.003 5.003 0 0 1 1.2 1.837c.22.563.48 1.412.55 2.97.075 1.687.094 2.193.094 6.462s-.02 4.78-.094 6.462c-.07 1.563-.33 2.406-.55 2.97a4.944 4.944 0 0 1-1.194 1.836 5.02 5.02 0 0 1-1.837 1.2c-.563.22-1.413.48-2.97.55-1.687.075-2.193.094-6.462.094s-4.78-.02-6.463-.094c-1.563-.07-2.406-.33-2.97-.55a4.956 4.956 0 0 1-1.837-1.194 5.02 5.02 0 0 1-1.2-1.837c-.22-.563-.48-1.413-.55-2.97-.075-1.687-.094-2.193-.094-6.462s.02-4.78.095-6.463c.07-1.563.33-2.406.55-2.97A4.968 4.968 0 0 1 4.73 4.723a5.003 5.003 0 0 1 1.837-1.2c.563-.22 1.412-.48 2.97-.55 1.68-.075 2.187-.094 6.462-.094zM16 0c-4.344 0-4.887.02-6.594.094-1.7.075-2.87.35-3.88.744-1.057.412-1.95.956-2.838 1.85a7.83 7.83 0 0 0-1.85 2.83C.444 6.54.168 7.7.094 9.4.02 11.113 0 11.656 0 16s.02 4.887.094 6.594c.075 1.7.35 2.87.744 3.88.413 1.057.956 1.95 1.85 2.838a7.816 7.816 0 0 0 2.83 1.844c1.02.394 2.182.67 3.882.744 1.706.075 2.25.094 6.594.094s4.888-.02 6.594-.094c1.7-.075 2.87-.35 3.88-.744 1.05-.406 1.945-.956 2.832-1.844s1.438-1.78 1.844-2.83c.394-1.02.67-2.182.744-3.882.075-1.706.094-2.25.094-6.594s-.02-4.887-.094-6.594c-.075-1.7-.35-2.87-.744-3.88a7.511 7.511 0 0 0-1.83-2.845A7.86 7.86 0 0 0 26.48.843C25.46.449 24.3.173 22.6.099 20.888.017 20.344 0 16 0z"></path><path d="M16 7.78c-4.537 0-8.22 3.682-8.22 8.22s3.682 8.22 8.22 8.22 8.22-3.682 8.22-8.22A8.223 8.223 0 0 0 16 7.78zm0 13.55a5.33 5.33 0 1 1 0-10.66 5.33 5.33 0 0 1 0 10.66zM26.462 7.456a1.919 1.919 0 1 1-1.918-1.92c1.06 0 1.918.86 1.918 1.92z"></path></svg>
                                        </span><span className="icon-label">Instagram</span>
                                    </a>
                                </li>
                            <li className="xing-item">
                                    <a href="https://xing.de/" title="xing" target="_blank" rel="noopener noreferrer">
                                    <span className="icon-box svg-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M4.862 6.32c-.275 0-.513.1-.63.286-.12.2-.1.45.024.706l3.12 5.4c.005.012.005.02 0 .025l-4.9 8.662c-.126.255-.12.51 0 .705a.68.68 0 0 0 .6.313h4.612c.688 0 1.025-.47 1.256-.894 0 0 4.794-8.48 4.98-8.813L10.75 7.18c-.23-.406-.575-.862-1.287-.862h-4.6zM24.25 0c-.688 0-.988.43-1.238.88 0 0-9.944 17.632-10.27 18.213.02.03 6.557 12.03 6.557 12.03.23.407.58.882 1.287.882H25.2c.275 0 .494-.106.613-.294.125-.2.12-.455-.012-.71l-6.5-11.895c-.005-.012-.005-.02 0-.03l10.22-18.07c.125-.256.13-.512.012-.713-.12-.188-.337-.294-.613-.294h-4.67z"></path></svg>
                                    </span><span className="icon-label">Xing</span>
                                </a>
                                </li>
                            <li className="linkedin-item">
                                    <a href="https://linkedin.com/" title="Linkedin" target="_blank" rel="noopener noreferrer">
                                    <span className="icon-box svg-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M12 12h5.535v2.837h.08c.77-1.38 2.654-2.837 5.463-2.837C28.92 12 30 15.637 30 20.367V30h-5.77v-8.54c0-2.037-.04-4.657-3-4.657-3.005 0-3.463 2.218-3.463 4.51V30H12V12zM2 12h6v18H2V12zm6-5a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path></svg>
                                    </span><span className="icon-label">Linkedin</span>
                                    </a>
                                </li>
                            <li className="youtube-item">
                            <a href="https://youtube.com/" title="Youtube" target="_blank" rel="noopener noreferrer">
                                <span className="icon-box svg-icon">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                        <path d="M30.662 5.003c-4.488-0.645-9.448-1.003-14.662-1.003s-10.174 0.358-14.662 1.003c-0.86 3.366-1.338 7.086-1.338 10.997s0.477 7.63 1.338 10.997c4.489 0.645 9.448 1.003 14.662 1.003s10.174-0.358 14.662-1.003c0.86-3.366 1.338-7.086 1.338-10.997s-0.477-7.63-1.338-10.997zM12 22v-12l10 6-10 6z"></path>
                                    </svg>
                                </span><span className="icon-label">youtube</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div></section>
                <section className="footer-box">
                    <div className="item-box">
                        <span className="copyright">© Coders Unlimited - <script type="text/javascript">document.write(new Date().getFullYear());</script>2022</span>
                        <nav className="footer-nav">
                            <a href="/exhibitor-login"><span>Exhibitor Login</span></a>
                            <a href="#" target="_blank"><span>Legal Notice</span></a>
                            <a href="#" target="_blank"><span>Data Privacy</span></a>
                            <a href="#" target="_blank"><span>FAQ</span></a>
                        </nav>
                    </div>
                </section>
    </footer>
    )
}