export default function SmallHero() {
    return (
        <section className="hero-content small-hero">
			<div className="bg-image match-header">
				<figure className="image-box">
					<img src="/images/bg-small.jpg" />
				</figure>
			</div>
		</section>        
    );
}