import React, { PureComponent, useEffect, useState } from "react";

import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/navigation';
import "swiper/css/bundle";
import 'swiper/css/virtual';

import { Navigation, Autoplay, Lazy, Virtual } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import axios from "axios";


export default function MeadiaPartnerSlider(props) {

    const [ partners,setPartner ] = useState([]);

    useEffect(() => {
      let isSubscribed = true;

      axios.get(process.env.REACT_APP_ENDPOINT + 'lib/storage/content/media.json', {
        headers: { 
          'Authorization': 'Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh'
        },
        auth: {
          username: 'cuatconference',
          password: 'relaunch'
        }
        })
      .then(res=>{
        if (isSubscribed) {
          setPartner(res.data);
        }
      })
      .catch(err=>{
        console.log(err);
      })

      return () => (isSubscribed = false)
    }, []);


  return (
    <div className="slider-center-box">
          <Swiper
            preloadImages={false}
            modules={[Navigation, Autoplay, Lazy]}
            lazy={true}
            spaceBetween={10}
            slidesPerView={2}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // when window width is <= 320px
              560: {
                slidesPerView: 3,
                slidesPerGroup: 1,
              },
              960: {
                slidesPerView: 5,
                slidesPerGroup: 1,
              },
              1080: {
                slidesPerView: 6,
                slidesPerGroup: 1,
              },
              1280: {
                slidesPerView: 7,
                slidesPerGroup: 1,
              },
            }}
            className="mySwiper"
          >
            {partners.map((slideContent, index) => (
                <SwiperSlide key={slideContent.uid} virtualIndex={index}>
                <figure className="image-box exhibitor-category platin">
                  <img data-src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + slideContent.logo} className="swiper-lazy" />
                </figure>
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-prev prev-3">
            <span className="icon-box">
              <i className="icon"></i>
            </span>
          </div>
          <div className="swiper-button-next next-3">
            <span className="icon-box">
              <i className="icon"></i>
            </span>
          </div>
        </div>
  )
}