import SmallHero from "../Components/SmallHero";
import MatchFilter from "./MatchFilter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

export default function Matchmaking () {

    document.body.classList.add('live');

    return (
        <div className="base-box fixed scroll-box" id="page-top">
        <Header />
        <div className="base-item" >
            <SmallHero />
        </div>
        <main className="main-content">
            <MatchFilter type="bookmark" />
        </main>
        <Footer />
        </div>
    );
}
