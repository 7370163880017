import axios from "axios";
import { Fragment, useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import MyAgendaContext from "./Context/myAgendaContext";
import ProgramContext from "./Context/programContext";
import { toast } from "react-toastify";
import { doRequest } from "../utils/doRequest";
import { decryptedUserId } from "../utils";

const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const CryptoJS = require("crypto-js");

export default function ProgramSection() {
  
  const [streamDates, setStreamDates] = useState([]);
  const [hallLabel, setHallLabel] = useState([]);
  const [stream1, setStream1] = useState([]);
  const [stream2, setStream2] = useState([]);
  const [stream3, setStream3] = useState([]);

  const [filteredStream1, setFilteredStream1] = useState();
  const [filteredStream2, setFilteredStream2] = useState();
  const [filteredStream3, setFilteredStream3] = useState();

  const [activeDay, setActiveDay] = useState();
  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [index3, setIndex3] = useState(0);

  const [userId, setUserId] = useState(0);

  const { userAgenda, setUserAgenda } = useContext(MyAgendaContext);
  const { program, setProgram } = useContext(ProgramContext);

  useEffect(() => {
    
    let isSubscribed = true;

    setUserId(decryptedUserId());

    axios
      .get( process.env.REACT_APP_ENDPOINT +  "lib/storage/content/program.json", {
        headers: {
          Authorization: "Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh",
        },
        auth: {
          username: "cuatconference",
          password: "relaunch",
        },
      })
      .then((res) => {
        if (isSubscribed) {
          setProgram(res.data);
          setStreamDates(res.data.streamDates);
          setHallLabel(res.data.hallLabel);
          setStream1(res.data.stream1);
          setStream2(res.data.stream2);
          setStream3(res.data.stream3);

          setActiveDay(
            res.data.stream1[0][Object.keys(res.data.stream1[0])[0]][0]["date"]
          );

          setFilteredStream1(res.data.stream1[0]);
          setFilteredStream2(res.data.stream2[0]);
          setFilteredStream3(res.data.stream3[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

      return () => (isSubscribed = false)
  }, []);

  /**
   * Set my agenda section on user change
   *
   */

  useEffect(() => {
    if (userId) {
      axios
        .get( process.env.REACT_APP_ENDPOINT + "lib/storage/users/" + userId + "/myagenda.json", {
          headers: {
            Authorization: "Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh",
          },
          auth: {
            username: "cuatconference",
            password: "relaunch",
          },
          params: {
            v: process.env.REACT_APP_V,
          },
        })
        .then((res) => {
          if (res.data.eventID) {
            setUserAgenda(res.data.eventID);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId]);

  function handleMainTabChange(id) {
    if (id == 1) {
      setActiveDay(stream1[0][Object.keys(stream1[0])[0]][0]["date"]);
    }
    if (id == 2) {
      setActiveDay(stream2[0][Object.keys(stream2[0])[0]][0]["date"]);
    }
    if (id == 3) {
      setActiveDay(stream3[0][Object.keys(stream3[0])[0]][0]["date"]);
    }
  }

  function handleTabChange(e, id) {
    e.preventDefault();
    switch (id) {
      case 1:
        setIndex1(streamDates[1].indexOf(e.target.innerText));
        setFilteredStream1(stream1[streamDates[1].indexOf(e.target.innerText)]);
        stream1.map((values) => {
          Object.keys(values).map((val) => {
            values[val].map((streamItem) => {
              if (streamItem.streamDate == e.target.innerText) {
                setActiveDay(streamItem.date);
              }
            });
          });
        });
        break;

      case 2:
        setIndex2(streamDates[2].indexOf(e.target.innerText));
        setFilteredStream2(stream2[streamDates[2].indexOf(e.target.innerText)]);
        stream2.map((values) => {
          Object.keys(values).map((val) => {
            values[val].map((streamItem) => {
              if (streamItem.streamDate == e.target.innerText) {
                setActiveDay(streamItem.date);
              }
            });
          });
        });
        break;
      case 3:
        setIndex3(streamDates[3].indexOf(e.target.innerText));
        setFilteredStream3(stream3[streamDates[3].indexOf(e.target.innerText)]);
        stream3.map((values) => {
          Object.keys(values).map((val) => {
            values[val].map((streamItem) => {
              if (streamItem.streamDate == e.target.innerText) {
                setActiveDay(streamItem.date);
              }
            });
          });
        });
        break;

      default:
        break;
    }
  }

  function toggleMyagenda(id, val) {
    
    const ids = toast.loading("Please wait...");
    let action = 'add';
    if(!val) { action = 'delete'; }

    doRequest('React', 'server.php', 'GET', { 
          eventId: id,
          type: "myagenda",
          user: userId,
          checked: val,
    }, true, (err,data) => {

        if(err) { console.log(err) }

        toast.update(ids, { render: action + "ing to my agenda", type: "default", isLoading: true, closeButton: true });

        doRequest('React', "lib/storage/users/" + userId + "/myagenda.json", 'GET', { 
          eventId: id,
          type: "myagenda",
          user: userId,
          checked: val,
        }, true, (err,data) => {
          
          toast.update(ids, { render: action + " agenda Successfully...", type: "success", isLoading: false, autoClose: 5000, hideProgressBar: false });
          setUserAgenda(data.eventID);
        });

      

    });

  
  }

  function FirstTabPagination({ id }) {
    let activeIndex = 0;
    if (id == 1) {
      activeIndex = index1;
    }
    if (id == 2) {
      activeIndex = index2;
    }
    if (id == 3) {
      activeIndex = index3;
    }

    return (
      <div key={id} className="program-pagination pagination-1-container">
        {streamDates &&
          streamDates[id] &&
          streamDates[id].map((date, index) => (
            <button
              onClick={(e) => handleTabChange(e, id)}
              value={date}
              className={`button program-day ${
                index == activeIndex && "active"
              }`}
              data-id={index}
              data-day={index}
              key={index}
            >
              <span className="button-label">{date}</span>
              <span className="icon-box">
                <i className="icon"></i>
              </span>
            </button>
          ))}
      </div>
    );
  }

  function Streams({ stream, tab }) {
    // console.log('Streams =>  '+ tab, stream)
    if (stream) {
      return Object.keys(stream).map((val, index) => {
        return (
          <Fragment key={index.toString()}>
            <div className="program-date-time active-date">
              <h3>{activeDay}</h3>
            </div>
            {tab && tab == 1 && (
              <div className="halls">
                <div className="hall-item hall-1">
                  <h3 className="res-hide regular">{hallLabel[1]}</h3>
                </div>
                <div className="hall-item hall-2">
                  <h3 className="res-hide regular">{hallLabel[2]}</h3>
                </div>
                <div className="hall-item hall-3">
                  <h3 className="res-hide regular">{hallLabel[3]}</h3>
                </div>
              </div>
            )}
            {tab && tab == 2 && (
              <div className="halls">
                <div className="hall-item hall-1">
                  <h3 className="res-hide regular">{hallLabel[4]}</h3>
                </div>
                <div className="hall-item hall-2">
                  <h3 className="res-hide regular">{hallLabel[5]}</h3>
                </div>
                <div className="hall-item hall-3">
                  <h3 className="res-hide regular">{hallLabel[6]}</h3>
                </div>
              </div>
            )}
            {tab && tab == 3 && (
              <div className="halls">
                <div className="hall-item hall-1">
                  <h3 className="res-hide regular">{hallLabel[7]}</h3>
                </div>
                <div className="hall-item hall-2">
                  <h3 className="res-hide regular">{hallLabel[8]}</h3>
                </div>
                <div className="hall-item hall-3">
                  <h3 className="res-hide regular">{hallLabel[9]}</h3>
                </div>
              </div>
            )}

            <div className="program-time">
              <strong>{val}</strong>
              <hr />
            </div>

            {val &&
              stream[val].map((streamItem, index) => (
                <div
                  key={index}
                  data-id={streamItem.stage}
                  className={`form-row event-item hall-${streamItem.stage}`}
                >
                  <h3 className="res-show regular">
                    {hallLabel[streamItem.stage]}
                  </h3>

                  <div className="programm-event-box">
                    <div className="field-item standard-radio">
                      <input
                        type="checkbox"
                        className="event-hall-check"
                        name={`event-hall-${streamItem.eventId}`}
                        id={`event-hall-${streamItem.eventId}`}
                        value={streamItem.eventId}
                        checked={
                          userAgenda &&
                          userAgenda.includes(streamItem.eventId) &&
                          "checked"
                        }
                        onChange={() => {}}
                      />
                      <label
                        onClick={(e) =>
                          toggleMyagenda(
                            streamItem.eventId,
                            userAgenda.includes(streamItem.eventId) ? false : true
                          )
                        }
                        htmlFor={`event-hall-${streamItem.eventId}`}
                        className="checkbox-label"
                      >
                        Add to MyAgenda
                      </label>
                    </div>

                    <div className="title-box">
                      <span className="title-item-en">{streamItem.title}</span>
                      <span className="title-item-de"></span>
                    </div>

                    <div className="stream-info-box">
                      <span className="firstname">{streamItem.firstname}</span>
                      <span className="lastname">{streamItem.lastname}</span>
                      <span className="companyname">{streamItem.company}</span>
                    </div>

                    <div className="programm-text-box">
                      {parse(streamItem.description)}
                    </div>

                    <div className="cooming-soon">
                      Stream will be starting soon
                    </div>
                  </div>
                </div>
              ))}
          </Fragment>
        );
      });
    }

    return <>Loading...</>;
  }

  return (
    <section className="modul program-tabs" id="programm-container">
      <div className="item-box">
        <h5>Program</h5>
        <div className="tab-box link-box">
          <div className="tab-item active">
            <button
              onClick={() => {
                handleMainTabChange(1);
              }}
              className="button tabbutton tradeshow"
              id="tradeshow"
            >
              <span className="button-label">
                {process.env.REACT_APP_PROGRAM_TAB_1}
              </span>
              <span className="icon-box">
                <i className="icon"></i>
              </span>
            </button>
          </div>
          <div className="tab-item">
            <button
              onClick={() => {
                handleMainTabChange(2);
              }}
              className="button tabbutton teletalk"
              id="speakersforum"
            >
              <span className="button-label">
                {process.env.REACT_APP_PROGRAM_TAB_2}
              </span>
              <span className="icon-box">
                <i className="icon"></i>
              </span>
            </button>
          </div>
          <div className="tab-item">
            <button
              onClick={() => {
                handleMainTabChange(3);
              }}
              className="button tabbutton conference"
              id="conference"
            >
              <span className="button-label">
                {process.env.REACT_APP_PROGRAM_TAB_3}
              </span>
              <span className="icon-box">
                <i className="icon"></i>
              </span>
            </button>
          </div>
        </div>
        <div className="tab-content" id="tradeshow-tab-content">
          <div className="tab-content-item">
            <div className="programm-select-form">
              <input type="hidden" id="filter-stream1-page" value="" />
              <input type="hidden" id="filter-stream1-per-page" value="" />
              <input type="hidden" id="total-filter-stream1-page" value="" />

              <FirstTabPagination id={1} />

              <div
                id="stream1-container"
                className="stream-container programm-select-form"
              >
                <Streams stream={filteredStream1} tab={1} />
              </div>

              <FirstTabPagination id={1} />

              <div className="button-box">
                <a
                  href="https://ccw.codersunlimited.com/fileadmin/user_upload/Agenda_tradeshow_speakers_forum.pdf"
                  target="_blank"
                  className="button create-pdf"
                >
                  <span className="button-label">Download Programm PDF</span>
                  <span className="icon-box">
                    <i className="icon"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-content hidden" id="speakersforum-tab-content">
          <div className="tab-content-item">
            <div className="programm-select-form">
              <input type="hidden" id="filter-stream2-page" value="" />
              <input type="hidden" id="filter-stream2-per-page" value="" />
              <input type="hidden" id="total-filter-stream2-page" value="" />

              <FirstTabPagination id={2} />

              <div
                id="stream2-container"
                className="stream-container programm-select-form"
              >
                <Streams stream={filteredStream2} tab={2} />
              </div>

              <FirstTabPagination id={2} />

              <div className="button-box">
                <a
                  target="_blank"
                  href="https://ccw.codersunlimited.com/fileadmin/user_upload/Agenda_tradeshow_speakers_forum.pdf"
                  className="create-pdf button"
                >
                  <span className="button-label">Download Programm PDF</span>
                  <span className="icon-box">
                    <i className="icon"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="tab-content hidden" id="conference-tab-content">
          <div className="tab-content-item">
            <div className="programm-select-form">
              <input type="hidden" id="filter-stream3-page" value="" />
              <input type="hidden" id="filter-stream3-per-page" value="" />
              <input type="hidden" id="total-filter-stream3-page" value="" />

              <FirstTabPagination id={3} />

              <div
                id="stream3-container"
                className="stream-container programm-select-form"
              >
                <Streams stream={filteredStream3} tab={3} />
              </div>

              <FirstTabPagination id={3} />

              <div className="button-box">
                <a
                  target="_blank"
                  href="https://ccw.codersunlimited.com/fileadmin/user_upload/Agenda_conference.pdf"
                  className="create-pdf button"
                >
                  <span className="button-label">Download Programm PDF</span>
                  <span className="icon-box">
                    <i className="icon"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
