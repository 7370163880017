import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../Context/ProfileContext";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import SmallHero from "../Components/SmallHero";
import { doRequest } from "../utils/doRequest";
import StepNavigation from "./StepNavigation";
import { cryptedUserId } from "../utils";
import { toast } from "react-toastify";

export default function UserEditor() {
  document.body.classList.add('backend');
  document.body.classList.add('bd-user-profile');

  const { profile } = useContext(ProfileContext);
  const [ salutation, setSalution] = useState(0);
  const [ title, setTitle] = useState('');
  const [ telephone, setTelephone ] = useState('');
  const navigate = useNavigate();

  useEffect(() => {

    let isSubscribed = true;
    if(isSubscribed) {
      setSalution(profile.salutation);
      setTitle(profile.title);
      setTelephone(profile.telephone);
    }
    
    return () => {isSubscribed = false;}
  }, [profile])
  

 

  function handleNext(e) {
    e.preventDefault();
    
    doRequest('Server', '/api/v1/Frontend/user/updateUser','POST', 
    {
      "userid": cryptedUserId(),
      "step": 1,
      "fields": {
          "salutation": salutation,
          "title": title,
          "telephone": telephone
      }
    }, true, (err, data) => {

      if(data.success) {
        toast.success('User Updated Successfully', {hideProgressBar: false  });
        return navigate('/user-editor/professional');
      }

    })

  }


  return (
    <div className="base-box scroll-box" id="page-top">
      <Header hideOptions={true} />
      <div className="base-item">
        <SmallHero />

        <main className="main-content">
          <StepNavigation />

          <section className="modul backend-editor step-1">
            <div className="item-box">
              <div className="step-content">
                <div className="form-box">
                  {Object.values(profile).length ? (
                    <form action="" method="post">
                    <div></div>
                    <h3>Your Profile – Personal Data</h3>
                    <p className="info-text">
                      <strong>
                        Your data is not visible to other users unless you
                        specify otherwise.
                      </strong>
                    </p>
                    <div className="form-row salutation-box">
                      <div className="field-item standard-radio">
                        <input
                          type="radio"
                          id="male"
                          name="salutation"
                          value={1}
                          checked={salutation == 1}
                          onChange={(e) => setSalution(e.target.value)}
                        />
                        <label className="radio-label" htmlFor="male">
                          Mr.
                        </label>
                      </div>
                      <div className="field-item standard-radio">
                        <input
                          type="radio"
                          id="female"
                          name="salutation"
                          value={3}
                          checked={salutation == 3}
                          onChange={(e) => setSalution(e.target.value)}
                        />
                        <label className="radio-label" htmlFor="female">
                          Ms.
                        </label>
                      </div>
                    </div>

                    <div className="form-row single-row-item title-box">
                      <div className="field-item">
                        <input
                          type="text"
                          id="user-title"
                          placeholder=" "
                          className="standard-field"
                          name="tx_user_edituser[user-title]"
                          value={title || ''}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <label className="field-label" htmlFor="user-title">
                          Title
                        </label>
                      </div>
                    </div>

                    <div className="form-row single-row-item name-box">
                      <div className="field-item">
                        <input
                          type="text"
                          id="user-firstname"
                          className="disabled-field"
                          name="tx_user_edituser[user-firstname]"
                          value={profile.first_name || ''}
                          disabled=""
                          readOnly={true}
                        />
                        <label className="field-label" htmlFor="user-firstname">
                          Firstname
                        </label>
                      </div>
                    </div>
                    <div className="form-row single-row-item lastname-box">
                      <div className="field-item">
                        <input
                          type="text"
                          id="user-lastname"
                          className="disabled-field"
                          name="tx_user_edituser[user-lastname]"
                          value={profile.last_name || ''}
                          disabled=""
                          readOnly={true}
                        />
                        <label className="field-label" htmlFor="user-lastname">
                          Lastame
                        </label>
                      </div>
                    </div>
                    <div className="form-row single-row-item email-box">
                      <div className="field-item">
                        <input
                          type="email"
                          id="user-email"
                          value={profile.email || ''}
                          className="disabled-field"
                          disabled=""
                          readOnly={true}
                        />
                        <label className="field-label" htmlFor="user-email">
                          E-Mail
                        </label>
                      </div>
                    </div>

                    <div className="form-row single-row-item phone-box">
                      <div className="field-item">
                        <input
                          type="text"
                          id="user-phone"
                          placeholder=" "
                          className="standard-field"
                          name="tx_user_edituser[user-phone]"
                          value={telephone || ''}
                          onChange={(e) => setTelephone(e.target.value)}
                        />
                        <label className="field-label" htmlFor="user-phone">
                          Phone (+49 112 765246)
                        </label>
                      </div>
                    </div>
                    <div className="button-box">
                      <div className="field-item">
                        {/* <Link className="button next show-loader" to='/user-editor/professional'>
                          <span className="button-label">Next</span>
                          <span className="icon-box">
                            <i className="icon"></i>
                          </span>
                        </Link> */}
                        
                        <button
                          className="button next show-loader"
                          value="next"
                          onClick={handleNext}
                        >
                          <span className="button-label">Next</span>
                          <span className="icon-box">
                            <i className="icon"></i>
                          </span>
                        </button>
                      </div>
                    </div>
                  </form>
                  ): ''}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
}
