import React, {createContext, useEffect, useState} from "react";
import { isLogin, logout } from "../utils";

export const AuthContext = createContext({
    isAuth: false,
    login: () => {},
    logout: () => {}
});



const AuthContextProvider = props => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
      setIsAuthenticated(isLogin);
    }, [])

    const loginHandler = () => {
        setIsAuthenticated(true);
        
    }

    const logoutHandler = () => {
        setIsAuthenticated(false);
        logout()
    }

    return (
        <AuthContext.Provider value={{login: loginHandler, isAuth: isAuthenticated, logout: logoutHandler}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;