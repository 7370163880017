import { useState, useEffect } from "react"
import axios from 'axios';
import { Link } from "react-router-dom";

export default function Exhibitor () {

	const companyLimit = 12;
	const [ Products, setProducts ]  = useState([]);
	const [ Companies, setCompanies ] = useState([]);
	const [ AllCompanies, setAllCompanies ] = useState([]);
	const [ AllFilteredCompanies, setAllFilteredCompanies ] = useState([]);
	const [ filterLetterVal, setFilterLetterVal ] = useState();
	const [ filterProductVal, setFilterProductVal ] = useState();
	const [ activePage, setActivePage ] = useState(1);
	const [ numberOfPage, setNumberOfPages ] = useState(0);


	useEffect(() => {

		axios.get(process.env.REACT_APP_ENDPOINT + 'lib/storage/content/exhibitor.json', {
			headers: { 
				'Authorization': 'Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh'
			},
			auth: {
			  username: 'cuatconference',
			  password: 'relaunch'
			}
		  })
		.then(res=>{
			setProducts(res.data.products);
			let companiesArr = [];
			for (const [key, value] of Object.entries(res.data.companies)) {
				companiesArr.push({id: key, companyname : value.companyname, alphabet: value.alphabet, logo: value.logo, products: value.products})
			}
			setAllCompanies(companiesArr);
			setCompanies(companiesArr.slice(0,companyLimit));
			setNumberOfPages(Math.ceil(companiesArr.length / companyLimit));
		})
		.catch(err=>{
			console.log(err);
		})

	}, []);

	useEffect(() => {
		
		if (filterLetterVal && filterProductVal ) {
			const filteredCompanies = AllCompanies.filter((cmp) => {
				if(cmp.products) {
					return cmp.products.split(" ").includes(filterProductVal.toString()) && cmp.alphabet === filterLetterVal;
				}
			});
			
			setNumberOfPages(Math.ceil(filteredCompanies.length / companyLimit));
			setCompanies(filteredCompanies); return true;
		}

		if(filterLetterVal) {
			const filteredCompanies = AllCompanies.filter((Companies) => Companies.alphabet == filterLetterVal);
			setNumberOfPages(Math.ceil(filteredCompanies.length / companyLimit));
			setCompanies(filteredCompanies); return true;
		}
		if(filterProductVal) {
			const filteredCompanies = AllCompanies.filter((cmp) => {
				if(cmp.products) {
					return cmp.products.split(" ").includes(filterProductVal.toString())
				}
			});
			setNumberOfPages(Math.ceil(filteredCompanies.length / companyLimit));
			setCompanies(filteredCompanies); return true;
		}

		if (!filterLetterVal && !filterProductVal ) {
			setNumberOfPages(Math.ceil(AllCompanies.length / companyLimit));
			setCompanies(AllCompanies.slice(0,companyLimit)); return true;
		 }

		 setActivePage(1);

	}, [ filterLetterVal , filterProductVal, AllFilteredCompanies ])

	useEffect(() => {
		if (filterLetterVal ||  filterProductVal) {

		} else {
			setCompanies(AllCompanies.slice( (companyLimit * activePage) - companyLimit , companyLimit * activePage ));
		}

	}, [activePage])



	function handleChange (e, event) {
		e.preventDefault();
		if(event == 'sorting-letters') { setFilterLetterVal(e.target.value); }
		if(event == 'sorting-products') { setFilterProductVal(e.target.value); }
	}

	function Pagination() {

		let paginationButtons = [];
		let customClass;
		
		paginationButtons.push( <button key={Math.random()} className="tab-previous-page" onClick={(e) => { e.preventDefault(); if(activePage > 1) { setActivePage(activePage - 1) } } } ><span className="icon-box arrow"><i className="icon"></i></span></button>);

		for (let index = 0; index < numberOfPage; index++) {
			if((activePage - 1) == index) {
				customClass = 'company-filter-page-link active';
			} else {
				customClass = 'company-filter-page-link';
			}
			paginationButtons.push(<button key={Math.random()} className={customClass} onClick={(e) => { e.preventDefault(); setActivePage(index + 1) } } data-page={index + 1} >{index + 1}</button>);
		}

		paginationButtons.push( <button key={Math.random()} className="tab-next-page" onClick={(e) => { e.preventDefault(); if(activePage < numberOfPage) { setActivePage(activePage + 1) } } } ><span className="icon-box arrow"><i className="icon"></i></span></button> );

		return (
			<>
				{paginationButtons}
			</>
		)
	}

    return (
        <div key='Exhibitor-section' className="tx-exhibitor">
		
	        <section className="modul filter-masonry-box company-live-ajax" id="exhibitor-gallery">
			<div className="item-box">
				<h5>Exhibitors</h5>
					<div className="company-filter-box filters">

						<div className="company-alphabetical-navi ui-group">
								{/* id="sorting-letters" */}
								<select onChange={e => handleChange(e, 'sorting-letters')} className="filter-select" value-group="letters" >
									<option value="">Filter by Name</option><option value="a">A</option><option value="b">B</option><option value="c">C</option><option value="d">D</option><option value="e">E</option><option value="f">F</option><option value="g">G</option><option value="h">H</option><option value="i">I</option><option value="j">J</option><option value="k">K</option><option value="l">L</option><option value="m">M</option><option value="n">N</option><option value="o">O</option><option value="p">P</option><option value="q">Q</option><option value="r">R</option><option value="s">S</option><option value="t">T</option><option value="u">U</option><option value="v">V</option><option value="w">W</option><option value="x">X</option><option value="y">Y</option><option value="z">Z</option>
								</select>
								<span className="icon-box select"><i className="icon"></i></span>
							</div>
							
							<div className="company-product-navi ui-group">
								{/* id="sorting-products" */}
								<select onChange={e => handleChange(e, 'sorting-products')} className="filter-select" value-group="topics" >
									<option value="">Filter by Product</option>
									{Products && Products.map( (product) => {
										return <option key={product.uid} value={product.uid}>{product.name}</option>
									})}
								</select>
								<span className="icon-box select"><i className="icon"></i></span>
							</div>
							<button className="button reset-button filter-topics" id="filter-reset"><span className="button-label">Reset</span><span className="icon-box reset"><i className="icon"></i></span></button>
					</div>

					
					<div className="sorting-box" id="company-box">
						<input type="hidden" id="total-filter-page" value="2" />
						<div className="logo-gallery ">

							{Companies && Companies.map((company, index) => (
								<div key={index} className={`filtering-item ${company.alphabet}`}>
									<Link to={`/company/${company.id}`}></Link>
									{/* <a data-id="{company.uid}" href="/company/coders-unlimited-ns">
										<input type="hidden" className="activity-title" value="WIR MACHEN DIGITALES VERTRIEBSMARKETING" />
									</a> */}
									<figure className="exhibitor-category">
										{company.logo && (
											<img alt="Actio" src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}${company.logo}`} className="lazyload" />
										)}
										<figcaption>{company.companyname}</figcaption>
									</figure>
								</div>
							))}	
						
							
						</div>
						<div className="company-filter-pagination">
							{Companies.length ? <Pagination />: ''} 
						</div>
					</div>
                </div>
		</section>

	</div>
    )
}