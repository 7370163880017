import MeadiaPartnerSlider from "../Components/MeadiaPartnerSlider";

export default function MediaPartner () {
    return (
        <div className="tx-exhibitor">
            <section className="modul modul-image-slider" id="media-partner-slider">
            <div className="item-box company-live-ajax">
                <h5>Media Partner</h5>
                <MeadiaPartnerSlider />
              </div>
            </section>
        </div>
    )
}