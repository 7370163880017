import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import $ from 'jquery'
import Isotope from "isotope-layout";
import jQueryBridget from "jquery-bridget";
import imagesLoaded from "imagesloaded";
import { doRequest } from "../utils/doRequest";
jQueryBridget( 'isotope', Isotope, $ );
jQueryBridget( 'imagesLoaded', imagesLoaded, $ );


export default function NewsRoom () {
    const [ randomNews,setRandomNews ] = useState();
    const [ mostViewedNews,setMostViewedNews ] = useState();

    const [ randomNewsShowCount, setRandomNewsShowCount ] = useState(0);
    const [ mostViewedShowCount, setMostViewedShowCount ] = useState(0);

    useEffect(() => {
        
        let isSubscribed = true;

        doRequest('React', 'lib/storage/content/news.json','GET', {}, false, (err, data) => { 
            if (isSubscribed) {
                setRandomNews(data.ourjsonContents.sort((a, b) => 0.5 - Math.random()));
                setMostViewedNews(data.mostViewedJsonContents);
                setTimeout(() => {
                    var $grid = $('.masonry').imagesLoaded( function() {
                        $('.masonry').isotope({
                            itemSelector: '.masonry-item',
                            percentPosition: true,
                            masonry: {
                            columnWidth: '.masonry-item'
                            }
                        });
                    });
                }, 1000);
            }
        });

        return () => (isSubscribed = false)

	}, []);
    

    function WhitePapper ({item}) {
        return (
            <div className={`flex-item masonry-item narrow-item ${item.class} `} >
                <div className="box-title">
                    <Link to={`/company/${item.company.uid}`} className="button whitepaper-opener link">
                        <span className="button-label">Whitepaper Brochures
                        </span>
                        <span className="icon-box whitepaper"><i className="icon"></i>
                        </span>
                        <input
                            type="hidden"
                            className="activity-title"
                            value="Dummy whitepaper"
                        />
                    </Link>
                </div>

                <div className="text-box">
                    <h4>{item.pdftitle}</h4>
                    {item.pdfdescription && parse(item.pdfdescription)}
                </div>

                <div className="product-overview-box">
                    {item.expertise && item.expertise.map((topic, index) => (
                        <Fragment key={index} ><span key={index}> # {topic} </span> <br /></Fragment>
                    ))}
                </div>
            </div>
        )
    }

    function Article ({item}) {
        return (
            <div className="masonry-item narrow-item company-live-ajax" >
                <div className="box-title">
                    <Link to={`/company/${item.company.uid}`} className="button">
                        <span className="button-label">Article</span>
                        <span className="icon-box read"><i className="icon"></i></span>
                        <input
                            type="hidden"
                            className="activity-title"
                            value="{item.title}"
                        />
                    </Link>
                </div>
                <figure className="image-box"></figure>
                <div className="text-box">
                    <h4>{item.title}</h4>
                    {parse(item.articleintro)}
                </div>
                <div className="product-overview-box">
                    {item.expertise && item.expertise.map((topic, index) => (
                        <Fragment key={index}><span> # {topic}</span> <br /></Fragment>
                    ) )}
                </div>
            </div>
        )
    }

    function NewsItem({item}) {

        if(item.type === 'whitepaper')
            return <WhitePapper key={Math.random()} item={item} />;
        
        if(item.type === 'article')
            return <Article key={Math.random()} item={item} />;


        return( <h1>Loading....</h1> )
    }
    

    function showMoreRandomNews() {
        let count = randomNewsShowCount + 10
        setRandomNewsShowCount(count);
        setTimeout(() => {
            $('.masonry').isotope( 'reloadItems' ).isotope();
        }, 0);
    }

    function showMoreMostViewdNews() {
        let count = mostViewedShowCount + 10
        setMostViewedShowCount(count)
        setTimeout(() => {
            $('.masonry').isotope( 'reloadItems' ).isotope();
        }, 0);
    }

    function resetMansonary() {
        setTimeout(() => {
            $('.masonry').isotope( 'reloadItems' ).isotope();
        }, 1000);
    }

    return (
        <section className="modul news-masonry-box company-live-ajax">
        <div className="item-box">
            <h5 className="txt-uppercase" style={{'margin': '0'}}>Newsroom</h5>
            <p>
                Check the latest news from our partners at one glance! For a deep dive
                please click and read more in the company spaces.
            </p>
            <div className="news-tabs newsroom-tabs">
            <button className="button nav-tab active" onClick={resetMansonary} id="news-random">
                <span className="button-label">Random</span>
                <span className="icon-box"><i className="icon"></i></span>
            </button>
            <button className="button nav-tab"  onClick={resetMansonary} id="news-most-viewed">
                <span className="button-label">Most viewed</span>
                <span className="icon-box"><i className="icon"></i></span>
            </button>
            </div>

            <div id="newsroom-container">
                <div className="masonry flex-box wrap cl-white">
                    {randomNews && randomNews.slice(0,randomNewsShowCount + 10).map((item, index) => (
                        <NewsItem key={'random'+ index} item={item} />
                    ))}
                    <div className="masonry-spacer"></div>
                </div>
                {randomNews && (randomNewsShowCount + 10) <= randomNews.length && (
                    <div className="button-box">
                        <button onClick={showMoreRandomNews} className="button call-to-action newsroom-showmore">
                            <span className="button-label">Show more</span>
                            <span className="icon-box"><i className="icon"></i></span>
                        </button>
                    </div>
                )}
            </div>

            <div id="most-viewed-container" style={{'display': 'none'}}>
                <div className="masonry" >
                    {mostViewedNews && mostViewedNews.slice(0,mostViewedShowCount+ 10).map((item,index) => (
                        <NewsItem key={'mostViewed'+index} item={item} />
                    )) }
                </div>
                {mostViewedNews && mostViewedShowCount <= mostViewedNews.length && (
                    <div className="button-box">
                        <button onClick={showMoreMostViewdNews} className="button call-to-action newsroom-showmore">
                            <span className="button-label">Show more</span>
                            <span className="icon-box"><i className="icon"></i></span>
                        </button>
                    </div>
                )}
            </div>
        </div>
        </section>

    )
}