import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Header from "../Components/Header";
import StickyLinks from "./StickyLinks";
import { doRequest } from "../utils/doRequest";

export default function CompanyDetial() {
  let { cmpName } = useParams();

  const [compantDetails, setCompanyDetail] = useState();

  useEffect(() => {

    doRequest('React', 'lib/storage/company/'+ cmpName +'.json', 'GET', {}, true, (err,data) => {
      console.log(err);
      setCompanyDetail(data)
    })

    // axios.get(process.env.REACT_APP_ENDPOINT + 'lib/storage/company/'+ cmpName +'.json', {
    //     headers: { 
    //         'Authorization': 'Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh'
    //     },
    //     auth: {
    //       username: 'cuatconference',
    //       password: 'relaunch'
    //     }
    //   })
    // .then(res=>{

    //     setCompanyDetail(res.data)
    //     console.log(res.data);
    // })
    // .catch(err=>{
    //     console.log(err);
    // })
  }, [])

  function Video({item}) {
    return (
      <div className={`masonry-item narrow-item ${item.class}`} >
            {/* <div className="box-title"><button className="video-opener" data-id="261"><span>Video</span></button></div>  */}
            <div className="box-title">
              <button className="button video-opener" data-id="26" data-number="1" data-type="video">
              <span className="button-label">Video</span>
              <span className="icon-box play-movie"><i className="icon"></i></span>
               <input type="hidden" className="activity-title" value="Digital Sales Marketing" />
              </button>
            </div>
            {/* <figure className="image-box">
              <img src="/fileadmin/exhibitor/that_is_sales.png" />
            </figure> */}
            <div className="text-box">
                  <h4>{item.videoheadline}</h4>
                  {item.videodescription && parse(item.videodescription)}
            </div>
            {/* <div className="product-overview-box">
               <ul className="product-list">
                     <li>AI and Chatbot</li>
                     <li>Controlling, Reporting, Monitoring</li>
               </ul>
            </div> */}
      </div>
    )
  }

  function Podcast({item}) {
    return (
      <div className={`masonry-item narrow-item ${item.class} podcast-box `} >
               {/* <div className="box-title"><button className="podcast-opener" data-id="263"><span>Podcast</span></button></div> */}

               <div className="box-title"><button className="button podcast-opener" data-id="26" data-number="1" data-type="podcast">
               <span className="button-label">{item.type}</span><span className="icon-box podcast"><i className="icon"></i></span>
                  <input type="hidden" className="activity-title" value="Digital Academy.Die beste ArtKunden zu…" />
               </button></div>

               
                  {/* <figure className="image-box">
                    <img src="/fileadmin/exhibitor/magnus.png" />
                  </figure> */}
               
               <div className="text-box">
                     <h4>{item.podcastheadline}</h4>
                     {item.podcastdescription &&  parse(item.podcastdescription)}
               </div>

               {/* <div className="product-overview-box">                  
                  <ul className="product-list">
                        <li>AI and Chatbot</li>
                        <li>Controlling, Reporting, Monitoring</li>
                  </ul>
               </div> */}
         </div>
    )
  }

  function Whitepaper({item}) {
    return (
        <div className={`masonry-item narrow-item ${item.class}`} >
    
              <div className="box-title">
                  {/* <a href="javascript:;" data-id="266" className="button whitepaper-opener"><span>Whitepaper Brochures</span></a>  */}
                  <button className="button whitepaper-opener" data-id="26" data-number="2" data-type="whitepaper">
                    <span className="button-label">Whitepaper Brochures</span>
                    <span className="icon-box whitepaper"><i className="icon"></i></span>
                    <input type="hidden" className="activity-title" value="Der Hybrid Seller hilft in dem Markenartikler" />
                  </button>
              </div>
              

              <div className="text-box">
                    <h4>{item.pdftitle}</h4>
                    {item.pdfdescription && parse(item.pdfdescription)}
              </div>
            {/* <div className="product-overview-box">  
              <ul className="product-list">
                    <li>Crisis and Change Management</li>
                    <li>Dialogue Service Providers, Out-, Insourcing</li>
              </ul>
            </div> */}
      </div>
    )
  }


  return (
    <>
        <main className="main-content">
        <Header />
        <StickyLinks />
        {compantDetails && (
        <section id="matchPlaceHolder" className="company-page">
          <div className="tx-exhibitor">
            <div className="base-item company-page" data-company-id="27">
              <section className="hero-content company-hero">
                <div className="bg-image">
                  <figure className="image-box">
                    {(compantDetails.company.headerimage) ? (
                      <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + compantDetails.company.headerimage} />
                    ):(
                      <img src={'/images/bg-live.jpg'} />
                    )}
                  </figure>
                </div>
                <div className="item-box">
                  <div className="intro-box">
                    <div className="member-badge">
                      <strong>{compantDetails.company.category} Partner</strong>
                      <span className={`icon-box ${compantDetails.company.category}`}>
                        <i className="icon"></i>
                      </span>
                    </div>
                    <figure className="image-box">
                      {(compantDetails.company.logo) ? (
                        <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + compantDetails.company.logo} />
                      ): (
                        <img src='/images/missing-logo.png' />
                      )}
                    </figure>
                    <div className="text-box">
                      <p>
                        <strong>{compantDetails.company.companyname}</strong>
                        <br />
                        {compantDetails.company.companyaddress}
                        <br />
                        {compantDetails.company.companypostal} {compantDetails.company.companycity}
                        <br />
                        {compantDetails.company.companycountry}
                        <br />
                        <a href={`tel:${compantDetails.company.companyphone}`}>{compantDetails.company.companyphone}</a>
                        <br />
                        <a href={compantDetails.company.companywebsite} target="_new">
                        {compantDetails.company.companywebsite}
                        </a>
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <main className="main-content">
                {compantDetails.company.mapimage && (
                  <section className="modul fair-map-box">
                    <div className="item-box">
                      <button className="button open-map" id="open-fair-map">
                        <span className="icon-box locator">
                          <i className="icon"></i>
                        </span>
                        <strong className="open">Show on map</strong>
                        <strong className="close">Close Map</strong>
                        <span className="icon-box close">
                          <i className="icon"></i>
                        </span>
                      </button>
                      <figure className="image-box">
                        <img
                          src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + compantDetails.company.mapimage}
                        />
                      </figure>
                    </div>
                  </section>
                )}

                {compantDetails.company.showroomadmin && compantDetails.company.showroomadmin.length ? (
                  <section class="modul staff-box">
                    <div class="item-box">
                      <h5>Get in touch with our staff</h5>
                      <div class="staff-item-box">
                        {console.log(compantDetails.company.showroomadmin)}
                        {compantDetails.company.showroomadmin.map((admin, index) => (
                          
                          <div class="staff-item colorstyle-1">
                            <div class="flex-item image-item">
                                    <figure class="image-box">
                                      <img src="/images/icon-user.svg" />
                                    </figure>
                            </div>
          
                            <div class="flex-item">
                              <div class="text-box">
                                <h5></h5>
                                <p><br />
                                </p>
                                <Link class="button show-profile" to={`/userinfo/${admin.uid}`} >
                                  <span class="button-label">Get in touch</span><span class="icon-box"><i class="icon"></i></span>
                                </Link>
                                {/* <a data-id="14" class="button show-profile" href="/user-info?tx_user_userinfo%5Buser%5D=14&amp;cHash=6a429fbc2851003900a00c63932133e4"></a> */}
                              </div>
                            </div>
                          </div>
                          ))}
                      </div>
                    </div>
                  </section>
                ):''}
                
                {compantDetails.company.aboutcompany && (
                  <section className="modul about-company-box">
                    <div className="item-box">
                      <div className="text-box">
                          {parse(compantDetails.company.aboutcompany)}
                      </div>
                    </div>
                  </section>
                )}
                
                {compantDetails.company.articletitle && (
                  <section className="modul article-content-box">
                    <div className="item-box">
                      <div className="article-content-intro">
                        {compantDetails.company.articleimage && (
                          <figure className="image-box">
                            <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + compantDetails.company.articleimage} />
                          </figure>
                        )}
                        
                        <div className="text-box cl-white">
                          <h3>{compantDetails.company.articletitle}</h3>
                          {parse(compantDetails.company.articleintro)}
                          <br />
                          <button className="button open-company-content">
                            <strong className="open">show more...</strong>
                            <strong className="close">show less...</strong>
                            <span className="icon-box">
                              <i className="icon"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="exhibitor-content">
                        {parse(compantDetails.company.articledescription)}
                      </div>
                    </div>
                  </section>
                )}
                
                {Object.keys(compantDetails.livestreams).length ? (
                  <section className="modul live-stream-box">
                  <div className="item-box">
                    <h5>Live Performance</h5>
                    <div className="ls-teaser-box">
                      {Object.values(compantDetails.livestreams).map((stream) => (
                        <div className={`stream-item stage-${stream.stage}`}>
                          <div className="content-box">
                            <div className="stage-box">Speakers Forum &amp; Teletalk Forum</div>
                            <div className="time-box">16:00 - Thu. September 02nd</div>
                            <h3>{stream.title}</h3>
                            {parse(stream.longtext)}
                            <div className="button-box record-available">Recording will be available soon</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  </section>
                ): ''}
                


                <section className="modul exhibitor-masonry-box">
                  <div className="item-box">
                    <h5>Our Content</h5>
                    
                      <Masonry  columnsCount={4} className="masonry">
                        {compantDetails.ourContent.map((content) => {
                          if (content.type == 'video') {
                          return <Video item={content} />;
                          }
                          if (content.type == 'podcast') {
                            return <Podcast item={content} />;
                          }
                          if (content.type == 'whitepaper') {
                            return <Whitepaper item={content} />;
                          }
                        })}                  
                      </Masonry>

                    {/* <div className="masonry">
                      

                      <div className="masonry-item narrow-item colorstyle-3 podcast-box youtube-podcast">
                        <div className="box-title">
                          <button
                            className="button podcast-opener"
                            data-id="27"
                            data-number="1"
                            data-type="podcast"
                          >
                            <span className="button-label">Podcast</span>
                            <span className="icon-box podcast">
                              <i className="icon"></i>
                            </span>
                            <input
                              type="hidden"
                              className="activity-title"
                              value="This is a great podcast"
                            />
                          </button>
                        </div>
                        <div className="text-box">
                          <h4>This is a great podcast</h4>
                          <p>Great Podcast</p>
                        </div>
                        <div className="product-overview-box"></div>
                      </div>

                      <div className="masonry-item narrow-item colorstyle-4">
                        <div className="box-title">
                          <button
                            className="button whitepaper-opener"
                            data-id="27"
                            data-number="2"
                            data-type="whitepaper"
                          >
                            <span className="button-label">Whitepaper Brochures</span>
                            <span className="icon-box whitepaper">
                              <i className="icon"></i>
                            </span>
                            <input
                              type="hidden"
                              className="activity-title"
                              value="Digital Sales Marketing"
                            />
                          </button>
                        </div>

                        <div className="text-box">
                          <h4>Digital Sales Marketing</h4>
                          <p>fomffwofnqwn dfoqojf</p>
                        </div>
                        <div className="product-overview-box"></div>
                      </div>
                    </div> */}
                  </div>
                </section>
              </main>
            </div>

            <footer
              id="companypage-footer"
              className="site-footer"
              role="contentinfo"
            >
              <a href="#page-top" className="totop">
                <span className="icon-box arrow">
                  <i className="icon"></i>
                </span>
              </a>
              <section className="footer-meta-box">
                <div className="item-box">
                  <div className="footer-content-box">
                    <div className="text-box">
                        <strong>{compantDetails.company.companyname}</strong>
                        <br />
                        {compantDetails.company.companyaddress}
                        <br />
                        {compantDetails.company.companypostal} {compantDetails.company.companycity}
                        <br />
                        {compantDetails.company.companycountry}
                        <br />
                        <a href={`tel:${compantDetails.company.companyphone}`}>{compantDetails.company.companyphone}</a>
                        <br />
                        <a href={compantDetails.company.companywebsite} target="_new">
                        {compantDetails.company.companywebsite}
                        </a>
                        <br />
                      <nav className="footer-nav company-nav">
                        <a href={compantDetails.company.companyimpressumlink}>
                          <span>Legal Notice</span>
                        </a>

                        <a href="#" target="_blank">
                          <span>Data Privacy</span>
                        </a>
                      </nav>
                    </div>
                    <div className="flex-item">
                      <div className="iframe-box">
                        <iframe
                          src={compantDetails.company.googlemaplink}
                          allowFullScreen=""
                          aria-hidden="false"
                          tabIndex="0"
                          width="600"
                          height="450"
                          frameBorder="0"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </footer>

            <div
              className="overlay flex-box center-center live-stream"
              id="live-stream"
            >
              <div className="item-box tab-box">
                <button className="shrink-enlarge se-overlay" id="shrink-enlarge">
                  <span className="icon-box shrink">
                    <i className="icon"></i>
                  </span>
                  <span className="icon-box enlarge">
                    <i className="icon"></i>
                  </span>
                </button>
                <button className="close close-overlay" id="close-stream-box">
                  <span className="icon-box close">
                    <i className="icon"></i>
                  </span>
                </button>
                <button className="position-button position-top" id="position-top">
                  <span className="icon-box arrow">
                    <i className="icon"></i>
                  </span>
                </button>
                <button
                  className="position-button position-bottom"
                  id="position-bottom"
                >
                  <span className="icon-box arrow">
                    <i className="icon"></i>
                  </span>
                </button>
                <button className="position-button position-left" id="position-left">
                  <span className="icon-box arrow">
                    <i className="icon"></i>
                  </span>
                </button>
                <button
                  className="position-button position-right"
                  id="position-right"
                >
                  <span className="icon-box arrow">
                    <i className="icon"></i>
                  </span>
                </button>

                <div className="overlay-container pd-8">
                  <div className="iframe-box">
                    {/* <iframe
                      id="overlay-iframe"
                      scrolling="no"
                      src="/stream-video"
                      frameborder="0"
                    ></iframe> */}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="overlay flex-box center-center stream-archive"
              id="stream-archive"
            >
              <div className="item-box">
                <button className="shrink-enlarge se-overlay" id="shrink-enlarge">
                  <span className="icon-box shrink">
                    <i className="icon"></i>
                  </span>
                  <span className="icon-box enlarge">
                    <i className="icon"></i>
                  </span>
                </button>
                <button className="close close-overlay" id="close-stream-box">
                  <span className="icon-box close">
                    <i className="icon"></i>
                  </span>
                </button>
                <button className="position-button position-top" id="position-top">
                  <span className="icon-box arrow">
                    <i className="icon"></i>
                  </span>
                </button>
                <button
                  className="position-button position-bottom"
                  id="position-bottom"
                >
                  <span className="icon-box arrow">
                    <i className="icon"></i>
                  </span>
                </button>
                <button className="position-button position-left" id="position-left">
                  <span className="icon-box arrow">
                    <i className="icon"></i>
                  </span>
                </button>
                <button
                  className="position-button position-right"
                  id="position-right"
                >
                  <span className="icon-box arrow">
                    <i className="icon"></i>
                  </span>
                </button>

                <div className="overlay-container pd-8">
                  <div className="iframe-box">
                    {/* <iframe
                      id="overlay-iframe"
                      scrolling="no"
                      src="/stream-video"
                      frameborder="0"
                      width="100%"
                      height="100%"
                    ></iframe> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        )}
        </main>
    </>
  );
}
