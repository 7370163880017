export const homepageSponsors = [
    '/images/exhibitor-logos/CPA_Global_Clarivate_platin.png',
    '/images/exhibitor-logos/Questel_platin.png',
    '/images/exhibitor-logos/uptoIP_platin.png',
    '/images/exhibitor-logos/European_Patent_Office_Co-Operation.png',
    '/images/exhibitor-logos/Cohausz_and_Florack_gold.png',
    '/images/exhibitor-logos/Greip_gold.png',
    '/images/exhibitor-logos/RWS_IP_Services_gold.png',
    '/images/exhibitor-logos/Anaqua_silver.png',
    '/images/exhibitor-logos/Clarivate_Dart-ip_silver.png',
    '/images/exhibitor-logos/Clarivate_Derwent_silver.png',
    '/images/exhibitor-logos/Dolcera_silver.png',
    '/images/exhibitor-logos/Evalueserve_silver.png',
    '/images/exhibitor-logos/FIZ_Karlsruhe_silver.png',
    '/images/exhibitor-logos/The_Global_IP_Matrix_media.png',
    '/images/exhibitor-logos/Gridlogics_silver.png',
    '/images/exhibitor-logos/GSI_Office_Management_silver.png',
    '/images/exhibitor-logos/IAMIP_silver.png',
    '/images/exhibitor-logos/Infoapps_silver.png',
    '/images/exhibitor-logos/Interface-projects_silver.png',
    '/images/exhibitor-logos/IP7Tech_silver.png',
    '/images/exhibitor-logos/Jostarndt_silver.png',
    '/images/exhibitor-logos/Jouve_silver.png',
    '/images/exhibitor-logos/LexisNexis_silver.png',
    '/images/exhibitor-logos/Mind_IP_silver.png',
    '/images/exhibitor-logos/Minesoft_silver.png',
    '/images/exhibitor-logos/NovumIP_silver.png',
    '/images/exhibitor-logos/NPI_silver.png'
];