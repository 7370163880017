import { useContext } from "react";
import overlayContext from "../Context/overlayContext";

export default function Overlays () { 
    const { showOverlay, setShowoverlay } = useContext(overlayContext);
    
    function closeOverlay() {
        setShowoverlay('');
    }

    function ShowTutorialsOverlay () {
        return (
            <div className="overlay active tutorial scroll-overlay" id="tutorial-overlay">
                <div className="item-box">
                        <button onClick={() => closeOverlay} className="close close-overlay">
                        <span className="icon-box close"><i className="icon"></i></span>
                        </button>
                        <div className="overlay-container">
                            <div className="iframe-box">
                                    <iframe id="overlay-iframe" scrolling="no" src="/sticky-video" frameBorder="0"></iframe>
                            </div>
                        </div>
                </div>
            </div>
        )
    }

    function ShowHallPlanOverlay() {
        return(
            <div className="overlay hallplan scroll-overlay active" id="hallplan-overlay">
                <div className="item-box">
                        <button className="close close-overlay">
                        <span className="icon-box close"><i className="icon"></i></span>
                        </button>
                        <div className="overlay-container">
                            <div className="iframe-box">
                                        <iframe id="overlay-iframe" src="/sticky-map" frameBorder="0"></iframe>
                            </div>
                        </div>
                </div>
            </div>
        )
    }

    function ShowFeedbackOverlay() {
        return(
            <div class="overlay feedback active" id="feedback-overlay">
                <div class="item-box">
                    <button class="close close-overlay">
                            <span class="icon-box close"><i class="icon"></i></span>
                    </button>
                    <div class="overlay-container">
                        <div class="iframe-box">
                                <iframe id="overlay-iframe" src="/feedback" frameBorder="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
        {showOverlay && showOverlay == 'feedback' ? <ShowFeedbackOverlay />: null}
        {showOverlay && showOverlay == 'hallplan' ? <ShowHallPlanOverlay />: null}
        {showOverlay && showOverlay == 'tutorial' ? <ShowTutorialsOverlay />: null}
        
        <div className="overlay live-stream scroll-overlay" id="livestream-overlay">
                <div className="item-box">
                <button className="shrink-enlarge se-overlay" id="shrink-enlarge">
                    <span className="icon-box shrink"><i className="icon"></i></span>
                    <span className="icon-box enlarge"><i className="icon"></i></span>
                </button>
                <button className="close close-overlay" id="close-stream-box">
                    <span className="icon-box close"><i className="icon"></i></span>
                </button>
                <button className="position-button position-top" id="position-top"><span className="icon-box arrow"><i className="icon"></i></span></button>
                <button className="position-button position-bottom" id="position-bottom"><span className="icon-box arrow"><i className="icon"></i></span></button>
                <button className="position-button position-left" id="position-left"><span className="icon-box arrow"><i className="icon"></i></span></button>
                <button className="position-button position-right" id="position-right"><span className="icon-box arrow"><i className="icon"></i></span></button>
                <div className="overlay-container">
                    <div className="iframe-box">
                        {/* <iframe id="overlay-iframe" src="/stream-video?uid=3&amp;recent=1" frameBorder="0"></iframe> */}
                    </div>
                </div>
                </div>
        </div>
        {/* <!-- Overlay Archived Video --> */}
        <div className="overlay flex-box center-center stream-archive" id="stream-archive">
            <div className="item-box tab-box">
            <button className="shrink-enlarge se-overlay" id="shrink-enlarge">
                    <span className="icon-box shrink"><i className="icon"></i></span>
                    <span className="icon-box enlarge"><i className="icon"></i></span>
            </button>
            <button className="close close-overlay" id="close-stream-box">
                <span className="icon-box close"><i className="icon"></i></span>
            </button>
            <button className="position-button position-top" id="position-top"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-bottom" id="position-bottom"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-left" id="position-left"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-right" id="position-right"><span className="icon-box arrow"><i className="icon"></i></span></button>
            <div className="overlay-container pd-8">
                <div className="iframe-box">
                    {/* <iframe id="overlay-iframe" scrolling="no" src="/stream-video" frameBorder="0" width="100%" height="100%"></iframe> */}
                </div>
            </div>
            </div>
        </div>
        {/* <!-- Overlay User Leads --> */}
        <div className="overlay flex-box center-center user-leads" id="user-leads">
            <div className="item-box tab-box">
            <button className="close close-overlay" id="close-stream-box">
                <span className="icon-box close"><i className="icon"></i></span>
            </button>
            <button className="position-button position-top" id="position-top"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-bottom" id="position-bottom"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-left" id="position-left"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-right" id="position-right"><span className="icon-box arrow"><i className="icon"></i></span></button>

            <div className="overlay-container cl-white pd-t12 pd-b5">
                <div className="overlay-title flex-box center-left"><strong>User Statistics</strong></div>
                <div className="col-box col-3">
                    <div className="col-item user-stats-container">
                    </div>
                </div>
            </div>
            </div>
        </div>
        
        </>
    )
}