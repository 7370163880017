import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import SmallHero from '../Components/SmallHero'


export default function UnAuthorizedPage() {
  return (
    <div className="base-box login-home" id="page-top">
      <Header />
      <SmallHero />
        <section className="modul modul-intro-text">
            <div className="text-box">
                <h3>Sorry But you are Unauthorized to view this page.</h3>
                <p>Please Login to continue.</p>
                <Link className="button call-to-action" to="/user-login" > Login </Link>
            </div>
        </section>
      <Footer />
    </div>
  )
}
