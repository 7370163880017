import Exhibitor from "./Exhibitor";

import Hero from "./Hero";
import Newsroom from "./Newsroom.js";
import FindYourBisnessPartner from './FindYourBuisnessPartner';
import SponsorsSection from './SponsorsSection';
import MediaPartner from './MediaPartner';
import FirstView from './FirstView';
import NextLiveStreamUpOnStage from './NextLiveStreamUpOnStage';
import StickyLinks from "./StickyLinks";
import AgendaSection from './AgendaSection';
import ProgramSection from "./ProgramSection";

import { MyAgendaProvider } from './Context/myAgendaContext';
import { ProgramProvider } from "./Context/programContext";

import { useEffect, useState } from "react";
import { doRequest } from "../utils/doRequest";
import { decryptedUserId } from '../utils';

import BeatLoader from "react-spinners/BeatLoader";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

export default function LivePage ({setIsLoggedInUser, isLoggedInUser}) {
    
    document.body.classList.add('live');
    document.body.classList.remove('countdown');

    const [userAgenda, setUserAgenda ] = useState([]);
    const [programs, setProgram ] = useState([]);
    const [userInfo, setUserInfo] = useState();
    
    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        let isSubscribed = true;

        if(!userInfo) {
            doRequest('React', '/lib/storage/content/Users.json','GET', {}, false, (err, data) => { 
                const uid = decryptedUserId();
                const user = data.filter(users => users.uid === uid);

                if (isSubscribed) {
                    setUserInfo(user);
                    setLoading(false);

                    if(!user.livepage) {
                        doRequest(
                            'React',
                            'server.php',
                            'GET', 
                            {
                                localId: process.env.REACT_APP_LOCAL_ID,
                                user: uid,
                                type: 'livePageVist'
                            },
                            false,
                            (err,data) => {}
                        );
                    }
                }
            });
        } 
        return () => (isSubscribed = false)
    },[userInfo])
    
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    if(loading) {
        return (<BeatLoader style={style} />);
    }

    return (
        <div className="base-box fixed scroll-box" id="page-top">
            <MyAgendaProvider value={{ userAgenda, setUserAgenda }}>
                    <StickyLinks />
                    <Header setIsLoggedInUser={setIsLoggedInUser} isLoggedInUser={isLoggedInUser} />
                    <div className="base-item" >
                        <Hero />
                        <main className="main-content">
                            <section id="searchResultPlaceHolder" className="modul news-masonry-box"></section>
                            {userInfo && !userInfo[0].livepage && <FirstView />}
                            <NextLiveStreamUpOnStage />
                            <ProgramProvider value={{ programs, setProgram }}>
                                <AgendaSection />
                                <ProgramSection />
                            </ProgramProvider>
                            <Newsroom />
                            <Exhibitor key={'exhibitor'} />
                            <FindYourBisnessPartner />
                            <SponsorsSection />
                            <MediaPartner />
                        </main>
                    </div>                
            </MyAgendaProvider>
            <Footer />
        </div>
    );
}