import * as React from "react";
import axios from "axios";

import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";

import ThanksToSponsersSlider from '../Components/ThanksToSponsersSlider'

import { toast } from 'react-toastify';

import { isLogin, login } from '../utils';
import { AuthContext } from "../Context/AuthContext";
import Header from "../Components/Header";

export default function ExhibitorLogin() {

  const authContext = useContext(AuthContext);

  const loginHandler = () => { authContext.login(); };

  const [email, setEmail] = React.useState();
  const [userState, setUserState] = React.useState();
  const [isLoading, setLoading] = useState(0);
  const [loginError, setLoginError] = useState(0);

  const [errorCode, setErrorCode] = useState(-1);

  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/";

  var CryptoJS = require("crypto-js");
  var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function handleSubmit(e) {

    setErrorCode(-2);
    axios
      .get(process.env.REACT_APP_ENDPOINT + "server.php", {
        headers: {
          Authorization: "Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh",
        },
        auth: {
          username: "cuatconference",
          password: "relaunch",
        },
        params: {
          localId: process.env.REACT_APP_LOCAL_ID,
          email: email,
          type: "loginEmail",
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log(res.data);
        let type = 'success';
        if(res.data.error) {type = 'error'}

        toast(res.data.response, {
          type,
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setErrorCode(res.data.errorCode);
      })
      .catch((err) => {
        
        console.log(err);
      });

    e.preventDefault();
    return false;
  }

  const hash = new URLSearchParams(location.search).get("hash");
  const status = new URLSearchParams(location.search).get("status");

  useEffect(() => {
    
    if (isLogin()) {
      return <Navigate replace to="/live" />;
    }

    if (hash) {
      
      setLoading(1);
      axios
        .get(process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + "?type=188189192", {
          headers: {
            Authorization: "Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh",
          },
          auth: {
            username: "cuatconference",
            password: "relaunch",
          },
          params: {
            hash: hash,
            status: status,
          },
        })
        .then((res) => {
          if (res.data.success) {
            login();
            let token = res.data.response.session;
            let uid = res.data.response.uid.toString();
            setLoading(0);

            if (uid) {
              var ciphertext = CryptoJS.AES.encrypt(uid, secretKey).toString();
              setCookie("fe_typo_user", token);
              sessionStorage.setItem("token", token);
              sessionStorage.setItem("auth", ciphertext);
              localStorage.setItem("contextVar", ciphertext);

              axios
                .get(
                  process.env.REACT_APP_ENDPOINT +
                    "/lib/storage/content/Users.json",
                  {
                    auth: {
                      username: "cuatconference",
                      password: "relaunch",
                    },
                  }
                )
                .then((res) => {
                  res.data.forEach((element) => {
                    if (uid == element.uid) {
                      // setuserInfo(element);
                      // console.log(element);
                      loginHandler();
                      navigate("/live");
                    }
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
            setLoading(2);
            setLoginError(1);
          }
        })
        .catch((err) => {
          console.log("res error: ");
          console.log(err);
        });
      //event.stopImmediatePropagation();
    } else {
      // console.log('no hash set');
    }

    document.body.classList.add('countdown');
    return () => {
      document.body.classList.remove('countdown');
    }

  }, []);

  if (isLoading === 1) {
    return <div className="App">Loading...</div>;
  }

  return (
    <div className="base-box login-home" id="page-top">
      <Header />

      <div className="base-item">
        <section className="hero-content">
          <div className="item-box">
            <div className="bg-image">
              <figure className="image-box">
                <img
                  srcSet="/images/bg-live.jpg 760w,
                                                   /images/bg-live.jpg 1080w"
                  sizes="(max-width: 1600px) 100vw, 1600px"
                  src="images/bg-live.jpg"
                />
              </figure>
            </div>

            <div className="hero-item-box">
              <div className="item-box">
                <section className="modul modul-intro-text">
                  <div className="item-box">
                    <div className="text-box">
                      <h3>Live and Digital - Worldwide</h3>
                      <p>
                        Cu@Conference tradefair is both a physical and a digital
                        event.
                      </p>
                    </div>
                  </div>
                </section>
                <section className="modul modul-start-login">
                  <div className="item-box">
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div></div>
                      <div className="form-box">
                        <h3>Login User</h3>
                        <div className="form-row status-state">
                          <div className="field-item standard-radio">
                            <input
                              type="radio"
                              name="user-online"
                              checked
                              id="on-site"
                              value="1"
                              onChange={(e) => setUserState(e.target.value)}
                            />
                            <label htmlFor="on-site">I am on site</label>
                          </div>
                          <div className="field-item standard-radio">
                            <input
                              type="radio"
                              name="user-online"
                              id="remote"
                              value="2"
                              onChange={(e) => setUserState(e.target.value)}
                            />
                            <label htmlFor="remote">I am remote</label>
                          </div>
                        </div>

                        <div className="form-row login-input">
                          <div className="field-item">
                            <input
                              type="email"
                              className="required-field"
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder=" "
                              required="required"
                            />
                            <label className="field-label" htmlFor="user-email">
                              E-Mail <span className="required-label">*</span>
                            </label>
                          </div>
                          <div className="field-item">
                            <button
                              className="button call-to-action login"
                              name="tx_user_login[submit]"
                              type="submit"
                            >
                              <span className="button-label">Login</span>
                              <span className="icon-box">
                                <i className="icon"></i>
                              </span>
                            </button>
                          </div>
                        </div>

                        {(() => {
                          if (errorCode === 101) {
                            return (
                              <p className="info-text validation-box invalid">
                                This email is not registered. Please register
                                here if you want to take part in the Traderfair.
                                <span className="icon-box">
                                  <i className="icon"></i>
                                </span>
                              </p>
                            );
                          } else if (errorCode === 0) {
                            return (
                              <p className="info-text validation-box valid">
                                Your Login email is sent, please check your
                                Email Account and klick on the Login Link
                                <span className="icon-box">
                                  <i className="icon"></i>
                                </span>
                              </p>
                            );
                          } else {
                            return "";
                          }
                        })()}

                        {loginError === 1 ? (
                          <p className="info-text validation-box invalid">
                            This Login-Link has expired. Request a new one!
                            <span className="icon-box">
                              <i className="icon"></i>
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        <p className="info-text">
                          * Please enter your email address and press “Login”.
                          You will then receive an email with the access link.
                          This link can only be used once and it cannot be
                          transferred. For return visits please repeat this
                          process. Incase you do not receive the email please
                          check your spam folder.
                        </p>
                      </div>
                    </form>
                  </div>
                </section>
                <section className="modul modul-ticket-frame"></section>
                <section className="modul modul-image-slider">
                    <ThanksToSponsersSlider />
                </section>
              </div>
            </div>
          </div>
          <div className="item-box flex-box bottom-left">
            <header className="page-title cl-white">
              <h3>Login User</h3>
            </header>
          </div>
        </section>
      </div>

      <footer id="colophon" className="site-footer" role="contentinfo">
        <section className="footer-box">
          <div className="item-box">
            <span className="copyright">
              © Coders Unlimited -{" "}
              <script type="text/javascript">
                document.write(new Date().getFullYear());
              </script>
              2021
            </span>
            <nav className="footer-nav">
              <a href="/exhibitor-login">
                <span>Exhibitor Login</span>
              </a>
              <a href="#" target="_blank">
                <span>Legal Notice</span>
              </a>
              <a href="#" target="_blank">
                <span>Data Privacy</span>
              </a>
              <a href="#" target="_blank">
                <span>FAQ</span>
              </a>
            </nav>
          </div>
        </section>
      </footer>

      <div className="overlay" id="video-overlay"></div>
      <div className="overlay" id="podcast-overlay"></div>
      <div className="overlay" id="whitepaper-overlay"></div>

      <div className="overlay" id="system-overlay">
        <div className="item-box">
          <button className="close close-overlay">
            <span className="icon-box close">
              <i className="icon"></i>
            </span>
          </button>
          <div className="overlay-container"></div>
        </div>
      </div>

      <div className="overlay live-stream" id="livestream-overlay">
        <div className="item-box">
          <button className="shrink-enlarge se-overlay" id="shrink-enlarge">
            <span className="icon-box shrink">
              <i className="icon"></i>
            </span>
            <span className="icon-box enlarge">
              <i className="icon"></i>
            </span>
          </button>
          <button className="close close-overlay" id="close-stream-box">
            <span className="icon-box close">
              <i className="icon"></i>
            </span>
          </button>
          <button className="position-button position-top" id="position-top">
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button
            className="position-button position-bottom"
            id="position-bottom"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button className="position-button position-left" id="position-left">
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button
            className="position-button position-right"
            id="position-right"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>

          <div className="overlay-container">
            <div className="iframe-box">
              {/* <iframe
                title="overlay-iframe-stream-video-1"
                id="overlay-iframe"
                scrolling="no"
                src="/stream-video"
                frameBorder="0"
              ></iframe> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="overlay flex-box center-center stream-archive"
        id="stream-archive"
      >
        <div className="item-box tab-box">
          <button className="shrink-enlarge se-overlay" id="shrink-enlarge">
            <span className="icon-box shrink">
              <i className="icon"></i>
            </span>
            <span className="icon-box enlarge">
              <i className="icon"></i>
            </span>
          </button>
          <button className="close close-overlay" id="close-stream-box">
            <span className="icon-box close">
              <i className="icon"></i>
            </span>
          </button>
          <button className="position-button position-top" id="position-top">
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button
            className="position-button position-bottom"
            id="position-bottom"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button className="position-button position-left" id="position-left">
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button
            className="position-button position-right"
            id="position-right"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>

          <div className="overlay-container pd-8">
            <div className="iframe-box">
              {/* <iframe
                title="overlay-iframe-stream-video-2"
                id="overlay-iframe"
                scrolling="no"
                src="/stream-video"
                frameBorder="0"
                width="100%"
                height="100%"
              ></iframe> */}
            </div>
          </div>
        </div>
      </div>

      <div
        className="overlay flex-box center-center user-leads"
        id="user-leads"
      >
        <div className="item-box tab-box">
          <button className="close close-overlay" id="close-stream-box">
            <span className="icon-box close">
              <i className="icon"></i>
            </span>
          </button>
          <button className="position-button position-top" id="position-top">
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button
            className="position-button position-bottom"
            id="position-bottom"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button className="position-button position-left" id="position-left">
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>
          <button
            className="position-button position-right"
            id="position-right"
          >
            <span className="icon-box arrow">
              <i className="icon"></i>
            </span>
          </button>

          <div className="overlay-container cl-white pd-t12 pd-b5">
            <div className="overlay-title flex-box center-left">
              <strong>User Statistics</strong>
            </div>
            <div className="col-box col-3">
              <div className="col-item user-stats-container">
                <p>
                  Some Valid Information on how to Design for Content management
                  Systems (CSM).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
